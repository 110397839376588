import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from '@mui/material/Avatar';
import { Container } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Typography, TextField, IconButton } from '@mui/material';
import Card from '@mui/joy/Card';
import { Table } from 'antd';
import { LoadingButton } from '@mui/lab';
import { DatePicker, Space } from 'antd';
import Checkbox from '@mui/material/Checkbox';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from '@mui/joy/Divider';


import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment-timezone';



import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


const API = process.env.REACT_APP_API
const user = localStorage.getItem("User")



const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));



function StatusCard({ o, handleClickOpen, setSelectedID }) {

  return (
    <div className='current-status' style={{ marginTop: "20px", display: "flex", borderRadius: "6px", alignItems: "center", textAlign: "center", height: "60px", backgroundColor: "#ebeff8", padding: "10px", borderRadius: "7px", justifyContent: "space-between", width: "70%", margin: "0 auto", marginBottom: "5px" }}>
      <p style={{ width: "60%", fontSize: "14px", color: "black", marginBottom: "0" }}>{o.Description}</p>
      <p style={{ width: "20%", fontSize: "12px", color: "black", marginBottom: "0" }}>Added by  {o.Fullname}</p>
      <p style={{ width: "20%", fontSize: "12px", color: "black", marginBottom: "0" }}> {o.Date}</p>

      <Button onClick={() => { setSelectedID(o.id); handleClickOpen() }} endIcon={<DeleteIcon sx={{ color: "black" }} />} />
    </div>
  )
}




// sessions

function SessionsCard({ type, value }) {

  return (
<Card sx={{ 
  minWidth: "300px", 
  width: "40%", 
  margin: "auto", 
  padding: "10px", 
  marginBottom: "10px", 
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  '@media (max-width:600px)': {
    width: "90%", // Adjust the width on mobile for better spacing
    padding: "5px", // Reduce padding on mobile for better fit
  }
}}>
  <Container>
    {/* Header Section: Session, Trainer, Date */}
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        '@media (max-width:600px)': {
          flexDirection: "column",  // Stack items vertically on mobile
          alignItems: "flex-start", // Align them to the left
        }
      }}
    >
      <Typography sx={{ 
        fontWeight: "bold", 
        fontFamily: "poppins", 
        color: "#1976d2", 
        fontSize: "16px",
        '@media (max-width:600px)': {
          fontSize: "14px", // Slightly smaller font size on mobile
        }
      }}>Type</Typography>
      <Typography sx={{ 
        fontWeight: "bold", 
        fontFamily: "poppins", 
        color: "#1976d2", 
        fontSize: "16px",
        '@media (max-width:600px)': {
          fontSize: "14px", // Slightly smaller font size on mobile
        }
      }}>Count</Typography>
    </Container>

    {/* Session Information */}
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        '@media (max-width:600px)': {
          flexDirection: "column",  // Stack items vertically on mobile
          alignItems: "flex-start", // Align them to the left
        }
      }}
    >
      <Typography sx={{ 
        fontFamily: "poppins", 
        fontWeight: "bold", 
        fontSize: "14px",
        '@media (max-width:600px)': {
          fontSize: "12px", // Smaller font size on mobile
        }
      }}>{type}</Typography>
      <Typography sx={{ 
        fontFamily: "poppins", 
        fontWeight: "bold", 
        fontSize: "14px",
        '@media (max-width:600px)': {
          fontSize: "12px", // Smaller font size on mobile
        }
      }}>{value}</Typography>
    </Container>
  </Container>
</Card>

  );
}


function Sessions({ id, rerender, setRender, startDate, setStartDate, endDate, setEndDate, medicalData }) {

  const [sessions, setSessions] = useState([])
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const count = {}


  const [age, setAge] = useState()
  const [customValue, setCustomValue] = useState("");




  for (const type of sessions) {
    if (count[type.type]) {
      count[type.type] += 1
    } else {
      count[type.type] = 1
    }
  }




  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString)
  };

  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString)
  };


  const [anchorEl, setAnchorEl] = useState(null); // For the Split sub-menu
  const validationSchema = Yup.object({
    session: Yup.string().required("Please select a session").notOneOf([''], 'Session cannot be empty'),
  });
  const initialValues = {
    session: "", // this is your select input field
  };

  const handleSelectChange = (e, setFieldValue) => {
    const selectedValue = e.target.value;
    setFieldValue("session", selectedValue);
  };

  // Handle custom value change
  const handleCustomValueChange = (e, setFieldValue) => {
    const customSession = e.target.value;
    setCustomValue(customSession);
    setFieldValue("session", customSession); // Update Formik value for custom session
  };



  React.useEffect(function () {
    async function fetchData() {
      try {
        const res = await fetch(`${API}/getSession/${id}?startDate=${startDate}&endDate=${endDate}`, {
          credentials: 'include',
        })
        if (!res.ok) {
          throw new Error("error fetching data")
        }

        const data = await res.json()
        setSessions(data.data)

      } catch (e) {
        console.log(e)
        toast.error(`Internal server error`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        setLoading(false)
      }
    } fetchData()
  }, [rerender, startDate, endDate])
  const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);


  const columns = [
    {
      title: 'Session',
      align: "center",
      dataIndex: 'type',
      sorter: {
				compare: (a, b) => sorter(a.type, b.type),
			},

    },
    {
      title: 'Trainer',
      dataIndex: 'trainer',
      align: "center",

      sorter: {
				compare: (a, b) => sorter(a.trainer, b.trainer),
			},
    },
    {
      title: 'Date',
      dataIndex: 'date',
      align: "center",

      sorter: {
        compare: (a, b) => new Date(a.date) - new Date(b.date),
      },
    },

  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };




  const handleSubmit = (values) => {
    setBtnLoading(true)
    fetch(`${API}/addSession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'customerID': id,
        'trainer': user,
        'type': values.session
      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRender(!rerender)

          setTimeout(() => {
            setBtnLoading(false)
          }, 2000);

        } else {
          toast.error(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

      }

      )

  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "100%",
        paddingBottom:"14px",
        margin: "auto",
        '@media (max-width:600px)': {
          width: "100%", // Make the container take up full width on small screens
          padding: "0 6",  // Padding to prevent text from sticking to edges
        }
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form
            style={{
              backgroundColor: "white",
              margin: "auto",
              padding: "10px",
              width: "100%",
              paddingTop: "0",
              marginTop: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              minHeight: "250px",
              height: "auto"

            }}
          >
            <Typography
               sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                fontSize: "1.1rem",
                margin: "auto",
                "@media (max-width:600px)": {
                  fontSize: "1.1rem",
                },
              }}
            >
            
              Register session
            </Typography>

            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                width: "50%",
                "@media (max-width:600px)": {
                  width: "70%",
                },
              }}
              size="small"
              error={touched.session && Boolean(errors.session)}
            >
              <InputLabel id="demo-select-small-label">Session</InputLabel>
              <Select
                disabled={customValue.length > 0}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={values.session}
                label="Session"
                onChange={(e) => handleSelectChange(e, setFieldValue)}
                sx={{ width: "100%" }}
              >
                <MenuItem value="Full body">Full body</MenuItem>
                <MenuItem value="Upper">Upper</MenuItem>
                <MenuItem value="Lower">Lower</MenuItem>
                <MenuItem value="Push">Push</MenuItem>
                <MenuItem value="Pull">Pull</MenuItem>
                <MenuItem value="Leg">Leg</MenuItem>
                <MenuItem value="Front">Front</MenuItem>
                <MenuItem value="Back">Back</MenuItem>
                <MenuItem value="Arm">Arm</MenuItem>
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Fitness or cardio">Fitness or cardio</MenuItem>
              </Select>

              <ErrorMessage name="session" component="div" style={{ color: "red" }} />
            </FormControl>

            {/* Display the custom TextField separately */}
            <Box sx={{
    width: { xs: "70%", md: "50%" }, // 70% on mobile, 50% on PC
    margin: "auto",
  }} >
              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    sx={{
                      fontWeight:"bold",
                      
                      fontFamily: "Poppins",
                      textAlign: "center", // This will center the text inside the AccordionSummary
                      width: "100%",
                    }}
                    component="span"
                  >
                    Custom session
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Custom Session TextField for user input */}
                  <TextField
                    label="Enter custom session"
                    size="small"
                    fullWidth
                    onChange={(e) => handleSelectChange(e, setFieldValue)}

                    variant="outlined"
                    sx={{
                      marginTop: "15px",
                      width: "100%", // Ensures it takes up the full width within the Accordion
                      marginLeft: "0", // To ensure no unintended left margin
                      marginRight: "0", // Similarly, ensures no right margin
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>

            <LoadingButton
              loading={false} // Adjust as needed
              sx={{
                background: "#008631",
                marginTop: "5px",
                margin: "auto",
                display: "block",
                fontWeight: "bold",
                fontFamily: "Poppins",
                borderRadius: "10px",
                height: "40px",
                width: "50%",
                ":hover": {
                  backgroundColor: "#008631",
                  border: "none",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#e0e0e0",
                  color: "#a0a0a0",
                },
                "@media (max-width:600px)": {
                  fontSize: "14px",
                  height: "35px",
                  width: "auto",
                  maxWidth: "200px",
                  margin: "10px auto",
                },
              }}
              type="submit"
              variant="outlined"
            >
              Confirm
            </LoadingButton>
          </Form>
        )}
      </Formik>

      {/* {sessions?.map(session => {
      return (
        <SessionsCard key={session.id} session={session} />
      );
    })} */}
      <Container
        sx={{
          width: "100%", // Set container to occupy full width
          maxWidth: "400px", // Set a max width for larger screens
          margin: "auto",
          display: "flex",
          flexDirection: "row", // Default layout for larger screens
          justifyContent: "space-evenly", // Space out the DatePickers horizontally
          alignItems: "center",
          gap: "10px", // Add spacing between items
          marginTop: "20px",
          "@media (max-width: 600px)": {
            flexDirection: "column", // Stack items vertically on smaller screens
            justifyContent: "center",
          },
        }}
      >
        <DatePicker
          placeholder="From"
          format={{
            format: "YYYY-MM-DD",
            type: "mask",
          }}
          onChange={onChangeStartDate}
          sx={{ width: "100%", maxWidth: "180px" }} // Adjust size for responsiveness
        />

        <DatePicker
          placeholder="To"
          format={{
            format: "YYYY-MM-DD",
            type: "mask",
          }}
          onChange={onChangeEndDate}
          sx={{ width: "100%", maxWidth: "180px" }} // Adjust size for responsiveness
        />
      </Container>

      <Table style={{ marginTop: "20px", width: "80%", margin: "10px auto" }}
        pagination={{ pageSize: 5 }}
        columns={columns}
        dataSource={sessions}
        onChange={onChange}
        bordered
        scroll={{ x: 'max-content' }}

      />
      {Object.entries(count).map(([type, value]) => {
        return <SessionsCard key={type} type={type} value={value} />;
      })}


    </Box>

  )
}



// delete confirmation modal

function DeleteConfirmation({ openConfirmation, setOpenConfirmation, handleDeleteNote }) {
  return (
    <Modal open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle sx={{ fontWeight: "bold" }}>
          <WarningRoundedIcon />
          Confirmation
        </DialogTitle>
        <DialogContent>
          Are you sure you want to entirely delete the note?
        </DialogContent>
        <DialogActions>
          <Button variant="solid" sx={{ backgroundColor: "darkred", color: "white" }} onClick={() => handleDeleteNote()}>
            Delete
          </Button>
          <Button variant="plain" color="neutral" onClick={() => setOpenConfirmation(false)}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

function ArchiveConfirmation({ openArchiveConfirmation, setOpenArchiveConfirmation, handleDeleteNote, handleArchiveNoteNote }) {
  return (
    <Modal open={openArchiveConfirmation} onClose={() => setOpenArchiveConfirmation(false)}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle sx={{ fontWeight: "bold" }}>
          <WarningRoundedIcon />
          Confirmation
        </DialogTitle>
        <DialogContent>
          Are you sure you want to archive the note?
        </DialogContent>
        <DialogActions>
          <Button variant="solid" sx={{ backgroundColor: "darkred", color: "white" }} onClick={() => handleArchiveNoteNote()}>
            Archive
          </Button>
          <Button variant="plain" color="neutral" onClick={() => setOpenArchiveConfirmation(false)}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}
function StatusAndNotes({ id, rerender, setRender }) {
  const [customValue, setCustomValue] = useState("");
  const [loading, setLoading] = React.useState()
  const [medicalData, setMedicalData] = useState([])
  const [btnLoading, setBtnLoading] = useState(false)
  const [checked, setChecked] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openArchiveConfirmation, setOpenArchiveConfirmation] = React.useState(false);


  const [noteID, setNoteID] = useState()



  const handleDeleteNote = () => {
    fetch(`${API}/deleteHealthNote`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'id': noteID,

      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRender(!rerender)
          setOpenConfirmation(false)



        } else {
          toast.error(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setOpenConfirmation(false)


      }

      )

  };


  const handleArchiveNoteNote = () => {
    fetch(`${API}/archiveHealthNote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'id': noteID,
        'Trainer':user

      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRender(!rerender)
          setOpenArchiveConfirmation(false)



        } else {
          toast.error(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setOpenArchiveConfirmation(false)


      }

      )

  };


  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log(checked)
  };
  React.useEffect(function () {
    async function fetchDate() {
      try {
        setLoading(true)
        const res = await fetch(`${API}/healthHistory/${id}`, {
          credentials: 'include',
        })
        const data = await res.json()
        if (!res.ok) {
          throw new Error("Error fetching data")
        }
        setMedicalData(data.data)
        console.log(data)
        
      } catch (e) {
        console.log(e)
        console.log(e)
        toast.error(`Internal server error`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        setLoading(false)
      }
    } fetchDate()

  }, [rerender])


  const handleSelectChange = (e, setFieldValue) => {
    const selectedValue = e.target.value;
    setFieldValue("session", selectedValue);
  };

  const handleSubmit = (values) => {
    setBtnLoading(true)

    console.log(values)

    fetch(`${API}/addHealtChronic`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'customerID': id,
        'type': values.session,
        'Trainer': user,
        "chronic": checked

      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        setRender(!rerender)

      })


  };
  const validationSchema = Yup.object({
    session: Yup.string().required("Please select a session").notOneOf([''], 'Session cannot be empty'),
  });
  const initialValues = {
    session: "", // this is your select input field
  };


  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "100%",
        paddingBottom:"20px",
        margin: "auto",
        '@media (max-width:600px)': {
          width: "100%", // Make the container take up full width on small screens
          padding: "0 6",  // Padding to prevent text from sticking to edges
        }
      }}
    >
      <DeleteConfirmation handleDeleteNote={handleDeleteNote} openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation} />
      <ArchiveConfirmation handleArchiveNoteNote={handleArchiveNoteNote} openArchiveConfirmation={openArchiveConfirmation} setOpenArchiveConfirmation={setOpenArchiveConfirmation} />


      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form
            style={{
              backgroundColor: "white",
              margin: "auto",
              padding: "10px",
              width: "100%",
              paddingTop: "0",
              marginTop: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              minHeight: "250px",
              height:"auto"
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                fontSize: "1.1rem",
                margin: "auto",
                "@media (max-width:600px)": {
                  fontSize: "1.1rem",
                },
              }}
            >
              Register condition
            </Typography>

            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                width: "50%",
                "@media (max-width:600px)": {
                  width: "70%",
                },
              }}
              size="small"
              error={touched.session && Boolean(errors.session)}
            >
              <InputLabel id="demo-select-small-label">Condition</InputLabel>
              <Select
                disabled={customValue.length > 0}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={values.session}
                label="Session"
                onChange={(e) => handleSelectChange(e, setFieldValue)}
                sx={{ width: "100%" }}
              >
                <MenuItem value="Osteoporosis">Osteoporosis</MenuItem>
                <MenuItem value="Arthritis">Arthritis</MenuItem>
                <MenuItem disabled divider>
                  Injuries
                </MenuItem>
                <MenuItem value="Shoulder injury">Shoulder injury</MenuItem>
                <MenuItem value="Elbow">Elbow injury</MenuItem>
                <MenuItem value="Hand">Hand injury</MenuItem>
                <MenuItem value="Hip">Hip injury</MenuItem>
                <MenuItem value="Knee">Knee injury</MenuItem>
                <MenuItem value="Foot">Foot injury</MenuItem>
                <MenuItem value="Spine">Spine injury</MenuItem>
                <MenuItem value="Cartilaginous">Cartilaginous</MenuItem>

                <Divider />

                {/* Chronic Conditions */}
                <MenuItem disabled divider>
                  Chronic Conditions
                </MenuItem>
                <MenuItem value="Diabetes">Diabetes</MenuItem>
                <MenuItem value="Hypertension">Hypertension</MenuItem>
                <MenuItem value="Coronary Heart Disease">Coronary Heart Disease</MenuItem>
                <MenuItem value="Cancer">Cancer</MenuItem>
                <MenuItem value="Pregnancy">Pregnancy</MenuItem>
                <MenuItem value="Lung Conditions">Lung Conditions</MenuItem>
                <MenuItem value="Intermittent Claudication/Peripheral Arterial Disease">
                  Intermittent Claudication/Peripheral Arterial Disease
                </MenuItem>
                <MenuItem value="Other Chronic Condition">Other</MenuItem>

                <Divider />
              </Select>
              <Box sx={{
    width: { xs: "100%", md: "100%" }, // 70% on mobile, 50% on PC
    margin: "auto",
  }} >
              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    sx={{
                      
                      fontFamily: "Poppins",
                      textAlign: "center", // This will center the text inside the AccordionSummary
                      width: "100%",
                    }}
                    component="span"
                  >
                    Custom
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Custom Session TextField for user input */}
                  <TextField
                    label="Custom condition"
                    size="small"
                    fullWidth
                    onChange={(e) => handleSelectChange(e, setFieldValue)}

                    variant="outlined"
                    sx={{
                      marginTop: "15px",
                      width: "100%", // Ensures it takes up the full width within the Accordion
                      marginLeft: "0", // To ensure no unintended left margin
                      marginRight: "0", // Similarly, ensures no right margin
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>

              <Box sx={{ display: "flex", margin: "8px auto", textAlign: "center", flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}


                />
                <Typography fontWeight="bold" fontFamily="poppins">
                  Chronic?
                </Typography>
              </Box>
              <ErrorMessage name="session" component="div" style={{ color: "red" }} />
            </FormControl>

            {/* Display the custom TextField separately */}


            <LoadingButton
              loading={false} // Adjust as needed
              sx={{
                background: "#008631",
                marginTop: "5px",
                margin: "auto",
                display: "block",
                fontWeight: "bold",
                fontFamily: "Poppins",
                borderRadius: "10px",
                height: "40px",
                width: "50%",
                ":hover": {
                  backgroundColor: "#008631",
                  border: "none",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#e0e0e0",
                  color: "#a0a0a0",
                },
                "@media (max-width:600px)": {
                  fontSize: "14px",
                  height: "35px",
                  width: "auto",
                  maxWidth: "200px",
                  margin: "10px auto",
                },
              }}
              type="submit"
              variant="outlined"
            >
              Confirm
            </LoadingButton>
          </Form>
        )}
      </Formik>


      {
        (medicalData.filter((o) => o.chronic === "true" && o.Status === "current").length > 0 ||
          medicalData.filter((o) => o.chronic === "false" && o.Status === "current").length > 0) && (
            <Divider sx={{fontFamily:"poppins", fontSize:"20px", marginTop:"20px", color:"black"}}>Current Conditions</Divider>
        )
      }

      {
        medicalData.filter((o) => o.chronic === "true" && o.Status === "current").length > 0 &&
        medicalData.filter((o) => o.chronic === "true" && o.Status === "current").map(o => (
          <ChronicCard
            setOpenArchiveConfirmation={setOpenArchiveConfirmation}
            setNoteID={setNoteID}
            setOpenConfirmation={setOpenConfirmation}
            key={o.id}
            o={o}
          />
        ))
      }

      {
        medicalData.filter((o) => o.chronic === "false" && o.Status === "current").length > 0 &&
        medicalData.filter((o) => o.chronic === "false" && o.Status === "current").map(o => (
          <NonChronicCard
            setOpenArchiveConfirmation={setOpenArchiveConfirmation}
            setNoteID={setNoteID}
            setOpenConfirmation={setOpenConfirmation}
            rerender={rerender}
            setRender={setRender}
            key={o.id}
            o={o}
          />
        ))
      }


      {
        (medicalData.filter((o) => o.chronic === "true" && o.Status === "history").length > 0 ||
          medicalData.filter((o) => o.chronic === "false" && o.Status === "history").length > 0) && (
            <Divider sx={{fontFamily:"poppins",  fontSize:"20px", marginTop:"20px", color:"black"}}>History</Divider>
        )
      }

      {
        medicalData.filter((o) => o.chronic === "true" && o.Status === "history").length > 0 &&
        medicalData.filter((o) => o.chronic === "true" && o.Status === "history").map(o => (
          <ChronicCard
            setOpenArchiveConfirmation={setOpenArchiveConfirmation}
            setNoteID={setNoteID}
            setOpenConfirmation={setOpenConfirmation}
            key={o.id}
            o={o}
          />
        ))
      }

      {
        medicalData.filter((o) => o.chronic === "false" && o.Status === "history").length > 0 &&
        medicalData.filter((o) => o.chronic === "false" && o.Status === "history").map(o => (
          <NonChronicCard
            setOpenArchiveConfirmation={setOpenArchiveConfirmation}
            setNoteID={setNoteID}
            setOpenConfirmation={setOpenConfirmation}
            rerender={rerender}
            setRender={setRender}
            key={o.id}
            o={o}
          />
        ))
      }

    </Box>
    //     <Container>
    //       <h6 style={{ margin: "10px", fontWeight: "bold" }}>Status And Notes</h6>
    //       {/* <div style={{ display: "flex", border: "1px solid #e4e4e4", borderRadius: "7px", height: "50px", width: "70%", margin: "0 auto", justifyContent: "space-evenly", marginTop: "50px", textAlign: "center", alignItems: "center" }} className='add-note'>
    //           <form onSubmit={(e) => AddStatus(e)} style={{ backgroundColor: "none", marginLeft: "0", width: "500px", height: "40px", padding: "10px", display: "flex" }}>
    //             <input required onChange={(e) => setDescription(e.target.value)} placeholder='Type status or note' type="text" style={{ fontSize: "13px", outlineStyle: "none", backgroundColor: "#f1f4f9", border: "1px solid #e4e4e4", marginBottom: "0", borderRadius: "5px", width: "300px", height: "30px" }} />
    //             <button style={{ height: "30px", border: "none", backgroundColor: "#2c608f", color: "white", width: "100px", marginBottom: "0", borderRadius: "5px", fontSize: "14px" }}>Add</button>
    //           </form>
    //         </div> */}

    //       <label style={{ backgroundColor: "#008631", display: "grid", color: "white", height: "25px", alignItems: "center", textAlign: "center", borderRadius: "20px", padding: "3px", marginLeft: "50%", fontSize: "12px", width: "90px", marginTop: "20px", marginBottom: "20px" }}>Current</label>

    //       {medicalData?.filter(m => {
    //         return (
    //           m.Status == "current"
    //         )
    //       }).map(o => {
    //         return (
    //           <StatusCard setSelectedID={setSelectedID} handleClickOpen={handleClickOpen} o={o} />

    //         )
    //       })}

    //       <label style={{ backgroundColor: "red", display: "grid", color: "white", height: "25px", alignItems: "center", textAlign: "center", borderRadius: "20px", padding: "3px", marginLeft: "50%", fontSize: "12px", width: "90px", marginTop: "20px", marginBottom: "20px" }}>History</label>

    //       {medicalData?.filter(m => {
    //         return (
    //           m.Status == "history"
    //         )
    //       }).map(o => {
    //         return (
    //           <NoStatusCard setSelectedID={setSelectedID} handleClickOpen={handleClickOpen} o={o} />

    //         )
    //       })}

    //       <div style={{ backgroundColor: "white", borderRadius: "10px", height: "300%", position: "relative", width: "70%", border: "1px solid #e4e4e4", margin: "40px auto" }} className='down-health'>

    //         <h6 style={{ margin: "10px", fontWeight: "bold" }}>Chronic</h6>
    //         {/* <div style={{ display: "flex", border: "1px solid #e4e4e4", borderRadius: "7px", height: "50px", width: "70%", margin: "0 auto", justifyContent: "space-evenly", marginTop: "50px", textAlign: "center", alignItems: "center" }} className='add-note'>
    //   <form onSubmit={(e) => AddChronic(e)} style={{ backgroundColor: "none", marginLeft: "0", width: "500px", height: "40px", padding: "10px", display: "flex" }}>
    //     <input required onChange={(e) => setChronic(e.target.value)} placeholder='Type chronic' type="text" style={{ fontSize: "13px", outlineStyle: "none", backgroundColor: "#f1f4f9", border: "1px solid #e4e4e4", marginBottom: "0", borderRadius: "5px", width: "300px", height: "30px" }} />
    //     <button style={{ height: "30px", border: "none", backgroundColor: "#2c608f", color: "white", width: "100px", marginBottom: "0", borderRadius: "5px", fontSize: "14px" }}>Add</button>
    //   </form>
    // </div> */}


    //         {medicalData?.filter(m => {
    //           return (
    //             m.Status == "chronic"
    //           )
    //         }).map(o => {
    //           return (
    //             <NoStatusCard setSelectedID={setSelectedID} handleClickOpen={handleClickOpen} o={o} />

    //           )
    //         })}




    //       </div>

    //     </Container>
  )
}



function Recommendations({ id }) {
  const [rerender, setRender] = useState(false)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState()
  const [openArchiveConfirmation, setOpenArchiveConfirmation] = useState()
  const [recommendationID, setRecommendationID] = useState()
  const currentRecs = data?.filter(rec => rec.status === "current") || [];
  const archivedRecs = data?.filter(rec => rec.status === "archive") || [];
  const role = localStorage.getItem("Roles")


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);




  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    // Add delete logic here
    setOpenConfirmation(true);
  };

  const handleArchive = () => {

    // Add archive logic here
    setOpenArchiveConfirmation(true)


  };
  const onSubmit = (values) => {

    fetch(`${API}/addHealthRecommednation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'customerID': id,
        'Trainer': user,
        'recommendation': values.recommendation
      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        setRender(!rerender)

      }

      )
  };

  const handleDeleteRecommendation = () => {
    fetch(`${API}/deleteRecommendations`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'id': recommendationID,
        'Trainer': user,

      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRender(!rerender)
          setOpenConfirmation(false)



        } else {
          toast.error(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setOpenConfirmation(false)


      }

      )

  };


  const handleArchiveRecommendation = () => {
    fetch(`${API}/archiveRecommendations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'id': recommendationID,
        'Trainer': user,
      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRender(!rerender)
          setOpenArchiveConfirmation(false)



        } else {
          toast.error(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setOpenArchiveConfirmation(false)


      }

      )

  };


  React.useEffect(function () {
    async function fetchData() {
      try {
        const res = await fetch(`${API}/getRecommendations/${id}`)
        if (!res.ok) {
          throw new Error("Error fetching data")
        }

        const data = await res.json()

        setData(data.data)

      } catch (e) {
        console.log(e)
        console.log(e)
        toast.error(`Internal server error`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        setLoading(false)
      }
    } fetchData()
  }, [rerender])
  const initialValues = {
    recommendation: '',
  };


  // Yup validation schema
  const validationSchema = Yup.object({
    recommendation: Yup.string()
      .min(5, 'Recommendation must be at least 5 characters long')
      .max(500, 'Recommendation cannot be more than 500 characters')
      .required('Recommendation is required'),
  });

  function DeleteConfirmation({ openConfirmation, setOpenConfirmation, handleDeleteNote }) {
    return (
      <Modal open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle sx={{ fontWeight: "bold" }}>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <DialogContent>
            Are you sure you want to entirely delete the note?
          </DialogContent>
          <DialogActions>
            <Button variant="solid" sx={{ backgroundColor: "darkred", color: "white" }} onClick={() => handleDeleteRecommendation()}>
              Delete
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpenConfirmation(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    )
  }

  function ArchiveConfirmation({ openArchiveConfirmation, setOpenArchiveConfirmation, handleDeleteNote, handleArchiveNoteNote }) {
    return (
      <Modal open={openArchiveConfirmation} onClose={() => setOpenArchiveConfirmation(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle sx={{ fontWeight: "bold" }}>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <DialogContent>
            Are you sure you want to archive the note?
          </DialogContent>
          <DialogActions>
            <Button variant="solid" sx={{ backgroundColor: "darkred", color: "white" }} onClick={() => handleArchiveRecommendation()}>
              Archive
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpenArchiveConfirmation(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    )
  }

  return (
    <Container>
      <DeleteConfirmation handleDeleteRecommendation={handleDeleteRecommendation} openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation} />
      <ArchiveConfirmation handleArchiveRecommendation={handleArchiveRecommendation} openArchiveConfirmation={openArchiveConfirmation} setOpenArchiveConfirmation={setOpenArchiveConfirmation} />


      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ touched, errors, handleChange, handleBlur, values }) => (
          <Form
            style={{
              backgroundColor: "white",
              margin: "auto",
              padding: "10px",
              width: "100%",
              paddingTop: "0",
              marginTop: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              height: "250px"
            }}
          >            <Box sx={{ marginBottom: 2 }}>
              <Typography  sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                fontSize: "1.1rem",
                margin: "auto",
                "@media (max-width:600px)": {
                  fontSize: "1.1rem",
                },
              }}
            >
                Register Recommendation
              </Typography>
              <TextField
                sx={{ width: "90%" }}

                id="recommendation"
                name="recommendation"
                label="Recommendation"
                variant="outlined"
                value={values.recommendation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.recommendation && Boolean(errors.recommendation)}
                helperText={touched.recommendation && errors.recommendation}
              />
            </Box>


            <LoadingButton
              loading={false} // Adjust as needed
              sx={{
                background: "#008631",
                marginTop: "5px",
                margin: "auto",
                display: "block",
                fontWeight: "bold",
                fontFamily: "Poppins",
                borderRadius: "10px",
                height: "40px",
                width: "50%",
                ":hover": {
                  backgroundColor: "#008631",
                  border: "none",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#e0e0e0",
                  color: "#a0a0a0",
                },
                "@media (max-width:600px)": {
                  fontSize: "14px",
                  height: "35px",
                  width: "auto",
                  maxWidth: "200px",
                  margin: "10px auto",
                },
              }}
              type="submit"
              variant="outlined"
            >
              Confirm
            </LoadingButton>
          </Form>
        )}
      </Formik>

      {currentRecs.length > 0 ? (<>
        <Typography sx={{ fontFamily: "poppins", width: "fit-content", fontWeight: "bold", margin: "10px auto", fontSize: "20px" }}>Current</Typography>

        {currentRecs?.filter(rec => rec.status == "current").map(rec => {
          return (

            <Card
              sx={{
                width: { xs: "90%", sm: "90%", md: "90%" }, // Adjust width based on screen size
                margin: "10px auto",
                position: "relative",
              }}
              variant="outlined"
            >
              {/* Three Dots Menu */}
              <IconButton
                onClick={(event) => {
                  setRecommendationID(rec.id); // Set the note ID
                  handleMenuOpen(event); // Open the menu and pass the event
                }}

                sx={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <MoreVertIcon />
              </IconButton>
              
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem
                  onClick={handleDelete}
                >
                  Delete
                </MenuItem>
                <MenuItem onClick={handleArchive}>Archive</MenuItem>
              </Menu>

              {/* Card Content */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  padding: { xs: "15px", sm: "25px" }, // Adjust padding based on screen size
                }}
              >
                {/* Type */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }, // Stack vertically on mobile
                    justifyContent: "space-between",
                    alignItems: { xs: "flex-start", sm: "center" }, // Align left on mobile
                    gap: "4px", // Add small gap for better readability
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      color: "#1976d2",
                      // fontSize: { xs: "50%", sm: "70%" }, // Full width on mobile
                    }}
                  >
                    Recommendation
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      fontSize: { xs: "14px", sm: "16px" },
                      width: { xs: "100%", sm: "70%" }, // Full width on mobile
                      wordBreak: "break-word",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      textAlign: { xs: "left", sm: "right" }, // Left align on mobile
                    }}
                  >
                    {rec.recommendation}
                  </Typography>
                </Box>

                {/* Employee */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      color: "#1976d2",
                      width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
                    }}
                  >
                    Added by
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                      fontWeight: "bold",
                      width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
                      textAlign: "right",
                    }}
                  >
                    {rec.added_by}
                  </Typography>
                </Box>

                {/* Date */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      color: "#1976d2",
                      width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
                    }}
                  >
                    Date
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "bold",
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                      width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
                      textAlign: "right",
                    }}
                  >
                    {rec.date}
                  </Typography>
                </Box>


              </Box>
            </Card>
          )
        })}
      </>) : ("")}



      {archivedRecs.length > 0 ? (<>
        <Typography sx={{ fontFamily: "poppins", width: "fit-content", fontWeight: "bold", margin: "auto", fontSize: "25px" }}>History</Typography>

        {archivedRecs?.map(rec => {
          return (
            <Card
              sx={{
                width: { xs: "90%", sm: "90%", md: "90%" }, // Adjust width based on screen size
                margin: "10px auto",
                position: "relative",
              }}
              variant="outlined"
            >
              {/* Three Dots Menu */}
              <IconButton
                onClick={(event) => {
                  setRecommendationID(rec.id); // Set the note ID
                  handleMenuOpen(event); // Open the menu and pass the event
                }}

                sx={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem
                  onClick={handleDelete}
                >
                  Delete
                </MenuItem>
                <MenuItem onClick={handleArchive}>Archive</MenuItem>
              </Menu>

              {/* Card Content */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  padding: { xs: "15px", sm: "25px" }, // Adjust padding based on screen size
                }}
              >
                {/* Type */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      color: "#1976d2",
                      width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
                    }}
                  >
                    Recommendation
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                      width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
                      textAlign: "right",
                    }}
                  >
                    {rec.recommendation}
                  </Typography>
                </Box>

                {/* Employee */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      color: "#1976d2",
                      width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
                    }}
                  >
                    Added by
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                      fontWeight: "bold",
                      width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
                      textAlign: "right",
                    }}
                  >
                    {rec.added_by}
                  </Typography>
                </Box>

                {/* Date */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      color: "#1976d2",
                      width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
                    }}
                  >
                    Date
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "bold",
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                      width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
                      textAlign: "right",
                    }}
                  >
                    {rec.date}
                  </Typography>
                </Box>





                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      color: "#1976d2",
                      width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
                    }}
                  >
                    Archived by
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                      fontWeight: "bold",
                      width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
                      textAlign: "right",
                    }}
                  >
                    {rec.archived_by}
                  </Typography>
                </Box>

                {/* Date */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "poppins",
                      color: "#1976d2",
                      width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
                    }}
                  >
                    Date
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "bold",
                      fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
                      width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
                      textAlign: "right",
                    }}
                  >
                    {rec.archive_date}
                  </Typography>
                </Box>
              </Box>
            </Card>
          )
        })}
      </>) : ("")}
    </Container>
  )
}


// non-chronic card


function NonChronicCard({ o, rerender, setRender, setOpenConfirmation, setNoteID, setOpenArchiveConfirmation }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const role = localStorage.getItem("Roles")




  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    // Add delete logic here
    console.log("Delete clickffed");
    setOpenConfirmation(true);
  };

  const handleArchive = () => {

    // Add archive logic here
    setOpenArchiveConfirmation(true)


  };
  return (
    <Card
      sx={{
        width: { xs: "90%", sm: "70%", md: "50%" }, // Adjust width based on screen size
                        margin: "10px auto",
        position: "relative",
      }}
      variant="outlined"
    >
      {/* Three Dots Menu */}

      {role === "Admin" || role === "Trainer"?( <IconButton
        onClick={(event) => {
          setNoteID(o.id); // Set the note ID
          handleMenuOpen(event); // Open the menu and pass the event
        }}

        sx={{ position: "absolute", top: "10px", right: "10px" }}
      >
        <MoreVertIcon />
      </IconButton>):("")}
     
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={handleDelete}
        >
          Delete
        </MenuItem>
        {o.archive_date?(""):(        <MenuItem onClick={handleArchive}>Archive</MenuItem>
)}
      </Menu>

      {/* Card Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: { xs: "15px", sm: "25px" }, // Adjust padding based on screen size
        }}
      >
        {/* Type */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "#1976d2",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Type
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.Description}
          </Typography>
        </Box>

        {/* Employee */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "#1976d2",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Added by
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              fontWeight: "bold",
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.Trainer}
          </Typography>
        </Box>

        {/* Date */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "#1976d2",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: "bold",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.Date}
          </Typography>
        </Box>

     {o.archived_by?(<>
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "#1976d2",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Archived by
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              fontWeight: "bold",
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.archived_by}
          </Typography>
        </Box>

        {/* Date */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "#1976d2",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: "bold",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.archive_date}
          </Typography>
        </Box>
     </>):("")}


      </Box>
    </Card>

  )

}

function ChronicCard({ o, setOpenConfirmation, setNoteID, setOpenArchiveConfirmation }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const role = localStorage.getItem("Roles")


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    // Add delete logic here
    console.log("Delete clickffed");
    setOpenConfirmation(true);
  };

  const handleArchive = () => {
    // Add archive logic here
    setOpenArchiveConfirmation(true)
  };
  return (
    //   <Card sx={{backgroundColor:"#ffcbd1" ,width: "50%", margin: "10px auto", border:"1px solid #f94449" }} variant="outlined">
    //   <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
    //     {/* Type */}
    //     <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    //       <Typography
    //         sx={{ fontWeight: "bold", fontFamily: "poppins", color: "#f94449", width: "30%" }}
    //       >
    //         Type
    //       </Typography>
    //       <Typography
    //         sx={{ fontWeight:"bold",fontFamily: "poppins", fontSize: "16px", width: "70%", textAlign: "right" }}
    //       >
    //         {o.Description}
    //       </Typography>
    //     </Box>

    //     {/* Employee */}
    //     <Box sx={{ display: "flex", justifyContent: "space-between",fontWeight:"bold", alignItems: "center" }}>
    //       <Typography
    //         sx={{ fontWeight: "bold", fontFamily: "poppins", color: "#f94449", width: "30%" }}
    //       >
    //         Employee
    //       </Typography>
    //       <Typography
    //         sx={{ fontFamily: "poppins", fontSize: "16px",fontWeight:"bold", width: "70%", textAlign: "right" }}
    //       >
    //         {o.Trainer}
    //       </Typography>
    //     </Box>

    //     {/* Date */}
    //     <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    //       <Typography
    //         sx={{ fontWeight: "bold", fontFamily: "poppins", color: "#f94449", width: "30%" }}
    //       >
    //         Date
    //       </Typography>
    //       <Typography
    //         sx={{ fontFamily: "poppins", fontWeight:"bold",fontSize: "16px", width: "70%", textAlign: "right" }}
    //       >
    //         {o.Date}
    //       </Typography>
    //     </Box>
    //   </Box>
    // </Card>

    <Card
      sx={{
        width: { xs: "90%", sm: "70%", md: "50%" }, // Adjust width based on screen size
        margin: "10px auto",
        position: "relative",
        backgroundColor: "#ffcbd1",
        border: "1px solid #f94449"
      }}
      variant="outlined"
    >
      {/* Three Dots Menu */}

      {role === "Admin" || role === "Trainer"? (      <IconButton
        onClick={(event) => {
          setNoteID(o.id); // Set the note ID
          handleMenuOpen(event);

          // Open the menu and pass the event
        }} sx={{ position: "absolute", top: "10px", right: "10px" }}
      >
        <MoreVertIcon />
      </IconButton>):("")}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={handleDelete}
        >
          Delete
        </MenuItem> 
        
        {o.archive_date?(""):(                <MenuItem onClick={() => handleArchive(o.id)}>Archive</MenuItem>

)}

      </Menu>

      {/* Card Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: { xs: "15px", sm: "25px" }, // Adjust padding based on screen size
        }}
      >
        {/* Type */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "red",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Type
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.Description}
          </Typography>
        </Box>

        {/* Employee */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "red",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Added by
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              fontWeight: "bold",
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.Trainer}
          </Typography>
        </Box>

        {/* Date */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "red",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: "bold",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.Date}
          </Typography>
        </Box>




        {o.archived_by?(<>
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "red",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Archived by
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              fontWeight: "bold",
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.archived_by}
          </Typography>
        </Box>

        {/* Date */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "poppins",
              color: "red",
              width: { xs: "40%", sm: "30%" }, // Adjust width based on screen size
            }}
          >
            Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: "bold",
              fontSize: { xs: "14px", sm: "16px" }, // Adjust font size based on screen size
              width: { xs: "60%", sm: "70%" }, // Adjust width based on screen size
              textAlign: "right",
            }}
          >
            {o.archive_date}
          </Typography>
        </Box>
     </>):("")}
      </Box>
    </Card>

  )

}

function HealthProfile() {
  const [description, setDescription] = React.useState()
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const role = localStorage.getItem("Roles")



  const [startDate, setStartDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [selectedStatusId, setSelectedID] = React.useState()
  const [chronic, setChronic] = useState()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [rerender, setRender] = useState(false)
  const [customerInfo, setCustomerInfo] = useState()
  const [subInfo, setSubInfo] = useState([])


  React.useEffect(function () {
    async function fetchData() {
      try {
        const res = await fetch(`${API}/customerHealthInfo/${id}`,
          {
            credentials: 'include',

          }
        )
        if (!res.ok) {
          throw new Error("Error fetching data")
        }
        const data = await res.json()


        setCustomerInfo(data)
        setSubInfo(data.customerSub)



      } catch (e) {
        console.log(e)
        toast.error(`Internal server error`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

      } finally {

      }
    } fetchData()
  }, [])








  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };




  const params = useParams()
  const id = params.customerID

  function AddStatus(e) {
    e.preventDefault()

    fetch(`${API}/addHealthstatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'customerID': id,
        'Trainer': user,
        'Description': description
      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setRender(!rerender)

      }

      )
  }





  function deleteStatus(e) {
    e.preventDefault()

    fetch(`${API}/deleteSTatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'cstomerByID': id,
        'statusID': selectedStatusId,
      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        setRender(!rerender)

      }

      )

  }



  const content = (
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit.`}</p>
  );
  return (
    <Container sx={{ minWidth: "100%", width: "100%", maxWidth: "100%", paddingLeft: "0" }}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >

        <DialogContent>
          <DialogContentText>
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={(e) => { deleteStatus(e); handleClose() }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>




      <Container sx={{ minWidth: "90%", width: "90%", maxWidth: "90%", margin: "auto", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", backgroundColor: "white", borderRadius: "10px", height: "300%", position: "relative", width: "70%", border: "1px solid #e4e4e4", margin: "40px auto" }}>


        <Container
          sx={{
            border: "1px solid #fffefa",
            backgroundColor: "#f5f5f5",
            width: { xs: "90%", sm: "70%", md: "40%" }, // Responsive width
            borderRadius: "10px",
            padding: { xs: "10px", sm: "5px" }, // Responsive padding
            marginTop: "10px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Soft shadow

          }}
        >
          {/* Avatar */}
          <Avatar
            sx={{
              height: { xs: "80px", sm: "100px", md: "120px" }, // Responsive size
              width: { xs: "80px", sm: "100px", md: "120px" }, // Responsive size
              margin: "auto",
            }}
            alt="Remy Sharp"
            src={customerInfo?.customerInfo[0].avatar}
          />

          {/* Heart Icon */}
          <Box
            sx={{
              mt: { xs: "40px", sm: "50px" }, // Reduced top margin
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "0"
            }}
          >
            <Box
              component="img"
              src="/heart.png"
              alt="heart"
              sx={{
                height: { xs: 60, sm: 50, lg: 50 }, // Smaller responsive sizes
                width: { xs: 100, sm: 120, lg: 150 },
                marginTop: "0"
                // objectFit: "contain",
              }}
            />
          </Box>

          {/* Full Name */}
          <Typography
            sx={{
              fontFamily: "Cairo",
              fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Responsive font size
              width: "fit-content",
              margin: "auto",
              fontWeight: "bold",
              color:"black"
            }}
          >
            {customerInfo?.customerInfo[0].Fullname}
          </Typography>

          {/* Customer ID */}
          <Typography
            sx={{
              width: "fit-content",
              margin: "auto",
              fontWeight: "bold",
              fontFamily: "Cairo",
              color:"black",
              fontSize: { xs: "14px", sm: "20px" }, // Responsive font size
            }}
          >
            {customerInfo?.customerInfo[0].customerID}
          </Typography>

          <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", border:"1px solid #a6a6a6", borderRadius:"7px", marginTop:"10px"}}>
          <Box sx={{display:"flex", width:"fit-content"}}>
          <Typography sx={{fontFamily:"poppins", fontWeight:"bold", }}>
              {subInfo[0]?.packageName} 
            </Typography>
            </Box>

            <Box sx={{display:"flex", width:"fit-content"}}>
              <FiberManualRecordIcon sx={{color:"red"}} />
              <Typography sx={{fontFamily:"poppins", fontWeight:"bold"}}>
              {subInfo[0]?.UsedSessions}
            </Typography>
            </Box>

            <Box sx={{display:"flex", width:"fit-content"}}>
            <FiberManualRecordIcon sx={{color:"green"}} />

            <Typography sx={{fontFamily:"poppins", fontWeight:"bold"}}>
            {subInfo[0]?.RemainingSessions}
            </Typography>
            </Box>


            <Box sx={{display:"flex", width:"fit-content"}}>
            <DateRangeIcon />

            <Typography sx={{fontFamily:"poppins", fontWeight:"bold"}}>
            {subInfo[0]?.EndDate}
            </Typography>
            </Box>
           
          </Box>
        </Container>

        <Box sx={{ width: '90%', margin: "auto", typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, margin: "auto", width: "fit-content", borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab sx={{ fontWeight: "bold", fontFamily: "poppins" }} label="Medical" value="1" />

                {role === "Admin" || role === "Trainer" ? (

                
                  <Tab sx={{ fontWeight: "bold", fontFamily: "poppins" }} label="Sessions" value="2" />
                ):("")}

              {role === "Admin" || role === "Trainer"? (
                  <Tab sx={{ fontWeight: "bold", fontFamily: "poppins" }} label="Notes" value="3" />


              ):("")}
                

              </TabList>
            </Box>
            <TabPanel sx={{ width: "100%", margin: "auto", padding: "1px" }} value="1">
              <StatusAndNotes
                id={id} rerender={rerender} setRender={setRender}
                setSelectedID={setSelectedID}
                handleClickOpen={handleClickOpen}
              />
            </TabPanel>
            <TabPanel sx={{ width: "100%", margin: "auto", padding: "2px" }} value="2">

              <Sessions startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setRender={setRender} id={id} rerender={rerender} />
            </TabPanel>

            <TabPanel sx={{ width: "100%", margin: "auto", padding: "2px" }} value="3">
              <Recommendations id={id} />
            </TabPanel>
          </TabContext>
        </Box>


      </Container>



    </Container>
  )
}


export default HealthProfile