import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/useAuth";
import { useState, useEffect } from "react";
import { CircularProgress, Box } from '@mui/material';


// const checkAuth = () => {
//     const cookies = document.cookie;   // Get all cookies
//     console.log(cookies);
  
//     // Check if the 'authToken' exists in the cookie string. Also trims and splits to prevent false positives.
//     const cookiesArray = cookies.split(';').map(cookie => cookie.trim());  // Split and trim cookies for reliability
    
//     // Find and check if 'authToken' cookie exists.
//     return cookiesArray.some(cookie => cookie.startsWith("authToken="));
//   };

  
const PrivateRoute = ({ requiredRoles }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [role, setRole] = useState(null);
    const API = process.env.REACT_APP_API;
  
    const checkAuth = async () => {
      try {
        const response = await fetch(`${API}/authCheck`, {
          method: 'GET',
          credentials: 'include', // Ensures cookies are sent
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log(data); // Log the response data
          setIsAuthenticated(data.message); // Assuming `message` indicates authentication status
          setRole(data.role); // Assuming `role` is returned
        } else {
          setIsAuthenticated(false); // Set state to false if the response is not OK
        }
      } catch (error) {
        setIsAuthenticated(false); // Set state to false if there's an error
      }
    };
  
    useEffect(() => {
      checkAuth(); // Call the authentication check on component mount
    }, []);
  
    // Handle loading state
    if (isAuthenticated === null) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
  
    // Redirect if the user is not authenticated
    if (isAuthenticated !== 'Authenticated') {
      return <Navigate to="/login" />; // Redirect to login if not authenticated
    }
  
    // Check if the user has one of the required roles
    if (requiredRoles && !requiredRoles.includes(role)) {
      return <Navigate to="/user" />; // Redirect to Unauthorized page if role does not match
    }
  
    // If everything checks out, render the child routes
    return <Outlet />;
  };
  
const PublicRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // Track auth state
    const API = process.env.REACT_APP_API;
  
    const checkAuth = async () => {
      try {
        const response = await fetch(`${API}/authCheck`, {
          method: "GET",
          credentials: "include", // Ensures cookies are sent
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log(data); // Log the response data
          setIsAuthenticated(data.message); // Update the authentication state
        } else {
          setIsAuthenticated(false); // Set state to false if the response is not OK
        }
      } catch (error) {
        setIsAuthenticated(false); // Set state to false if there's an error
      }
    };
  
    useEffect(() => {
      checkAuth(); // Call the authentication check on component mount
    }, []); // Empty dependency array ensures this only runs once
  
    // Use another useEffect to log isAuthenticated whenever it changes
    useEffect(() => {
    }, [isAuthenticated]); // This effect will run every time isAuthenticated changes
  
    if (isAuthenticated === null) {
      return (
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
      )
     
    }
  
    if (isAuthenticated == "Authenticated") {
      return <Navigate to="/user" />; // If not authenticated, redirect to login
    }
  
    return <Outlet />; // If authenticated, render the child routes
};

export { PrivateRoute, PublicRoute };
