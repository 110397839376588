import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Navbar from '../navbar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import { Transition } from 'react-transition-group';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Sheet from '@mui/joy/Sheet';
import ModalClose from '@mui/joy/ModalClose';
import * as Yup from 'yup';
function DietPage() {
	const API = process.env.REACT_APP_API
	const Role = localStorage.getItem("Roles")
	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)
	const [packages, setPackages] = useState([])
	const [packageID, setPackageID] = useState()
	const [packageName, setPackageName] = useState()
	const [packagePrice, setPackagePrice] = useState()
	const [rerender, setRender] = useState()
	const [open, setOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState()

	const handleClickOpen = (id) => {
		setPackageID(id)
		console.log(id)
		setDeleteOpen(true);
	};

	const handleClose = () => {
		setDeleteOpen(false);
	};
	const initialValues = {
		packageName: packageName,
		packagePrice: packagePrice,
	};

	const validationSchema = Yup.object().shape({
		packageName: Yup.string()
			.required('Package name is required'),
		packagePrice: Yup.number()
			.required('Package price is required')
			.positive('Package price must be a positive number')
			.integer('Package price must be an integer'),
	});

	const handleSubmit = (values) => {
		console.log(values);
		updatePackage(values)
	};

	function Modify(pack) {
		console.log(pack)
		setPackageID(pack.packageID)
		console.log(pack.packageID)
		setPackageName(pack.Name)
		setPackagePrice(pack.Price)
		setOpen(true)
		// handleTrainerOpen()
	}


	function Delete(pack) {
		setPackageID(pack.packageID)


		// handleClickOpenDelete()
	}

	useEffect(function () {
		async function fetchData() {
			try {
				const res = await fetch(`${API}/dietPackages`)
				const data = await res.json()
				console.log(data)
				setPackages(data.data)



			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchData()
	}, [rerender])


	function deletePackage() {
		fetch(`${API}/deleteDietPack`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'packageID': packageID,


			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(result.Message, {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
				}
				handleClose()
				setRender(!rerender)
			})


	}


	function updatePackage(values) {
		fetch(`${API}/editDietPack`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'packageID': packageID,
				'packageName': values.packageName,
				"packagePrice": values.packagePrice,


			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(result.Message, {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
				} else {
					toast.error("Something went wrong", {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
				}
				setOpen(false)
				setRender(!rerender)
			})


	}




	return (
		<Box sx={{ height: "100%", width: "100%" }}>
		<ToastContainer
		  autoClose={false}
		  position="top-center"
		  hideProgressBar={false}
		  newestOnTop={false}
		  closeOnClick
		  rtl={false}
		  pauseOnFocusLoss
		  draggable
		  pauseOnHover
		  theme="colored"
		/>
		
		<Dialog
		  open={deleteOpen}
		  onClose={handleClose}
		  aria-labelledby="alert-dialog-title"
		  aria-describedby="alert-dialog-description"
		  maxWidth="sm" // Adjusted for mobile responsiveness
		  fullWidth // Ensures the dialog uses the full width of the screen
		>
		  <DialogTitle sx={{ margin: "auto", fontFamily: "poppins" }}>
			Are you sure?
		  </DialogTitle>
		  <DialogContent>
			<p style={{ width: "100%", textAlign: "center" }}>Are you sure you want to delete this package?</p>
		  </DialogContent>
		  <DialogActions sx={{ justifyContent: "center" }}>
			<Button onClick={handleClose}>Cancel</Button>
			<Button onClick={deletePackage}>Confirm</Button>
		  </DialogActions>
		</Dialog>
  
		{/* <Modal
		  open={open}
		  onClose={() => setOpen(false)}
		>
		  <Box sx={{ width: { xs: '400px', sm: '400px' }, backgroundColor: 'white', padding: 2 }}>
			<DialogTitle>Edit Diet Package</DialogTitle>
			<DialogContent>
			  <Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			  >
				{({ handleChange, handleBlur, values, errors, touched }) => (
				  <Form >
					<TextField
					  name="packageName"
					  label="Package Name"
					  onChange={handleChange}
					  onBlur={handleBlur}
					  value={values.packageName}
					  error={Boolean(touched.packageName && errors.packageName)}
					  helperText={<ErrorMessage name="packageName" />}
					  fullWidth
					  margin="normal"
					/>
					<TextField
					  name="packagePrice"
					  label="Package Price"
					  type="number"
					  onChange={handleChange}
					  onBlur={handleBlur}
					  value={values.packagePrice}
					  error={Boolean(touched.packagePrice && errors.packagePrice)}
					  helperText={<ErrorMessage name="packagePrice" />}
					  fullWidth
					  margin="normal"
					/>
					<Button sx={{ width: "100%", marginTop: 2 }} type="submit" variant="contained" color="primary">
					  Submit
					</Button>
				  </Form>
				)}
			  </Formik>
			</DialogContent>
		  </Box>
		</Modal> */}



<Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
		
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:"10px" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			  >
				{({ handleChange, handleBlur, values, errors, touched }) => (
				  <Form  style={{marginLeft:"0",marginTop:"0",paddingTop:"0",width:"80%",height:"80%",backgroundColor:"white", margin:"auto" }} >
					<TextField
					  name="packageName"
					  label="Package Name"
					  onChange={handleChange}
					  onBlur={handleBlur}
					  value={values.packageName}
					  error={Boolean(touched.packageName && errors.packageName)}
					  helperText={<ErrorMessage name="packageName" />}
					  fullWidth
					  margin="normal"
					/>
					<TextField
					  name="packagePrice"
					  label="Package Price"
					  type="number"
					  onChange={handleChange}
					  onBlur={handleBlur}
					  value={values.packagePrice}
					  error={Boolean(touched.packagePrice && errors.packagePrice)}
					  helperText={<ErrorMessage name="packagePrice" />}
					  fullWidth
					  margin="normal"
					/>
					<Button sx={{ width: "100%", marginTop: 2, height:"40px", borderRadius:"12px" }} type="submit" variant="contained" color="primary">
					  Submit
					</Button>
				  </Form>
				)}
			  </Formik>
        </Sheet>
      </Modal>
  
		<Navbar />
		
		<Container sx={{ margin: { xs: "50px auto 10px auto", sm: "50px auto 10px auto" }, width: "fit-content" }}>
		  {Role === "Admin" && (
			<Button
			  sx={{
				width: { xs: "100%", sm: "100%" },
				height: "40px",
				borderRadius: "7px",
				backgroundColor: "#00072D",
				margin: "auto"
			  }}
			  onClick={() => navigate(`/add-diet-package`)}
			  variant="contained"
			  endIcon={<AddIcon style={{ color: "white" }} />}
			>
			  Add Package
			</Button>
		  )}
		</Container>
  
		<TableContainer sx={{ width: { xs: "100%", sm: "70%" }, margin: "10px auto" }} component={Paper}>
		  <Table
			sx={{ minWidth: 650, width: "100%", tableLayout: 'fixed' }} // Fixed layout for equal cell widths
			aria-label="simple table"
		  >
			<TableHead sx={{ backgroundColor: "#e4e4e4", fontWeight: "bold", fontFamily: "poppins" }}>
			  <TableRow>
				<TableCell sx={{ fontWeight:"bold", fontFamily:"poppins", align:"center" ,fontWeight:"bold"}}  align="center">Package</TableCell>
				<TableCell sx={{ fontWeight:"bold", fontFamily:"poppins", align:"center" ,fontWeight:"bold"}}  align="center">Price</TableCell>
				<TableCell align="center"></TableCell>
				<TableCell align="center"></TableCell>
			  </TableRow>
			</TableHead>
			<TableBody>
			  {packages?.map((pack) => (
				<TableRow key={pack.Name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				  <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins", align:"center" }}  align="center">{pack.Name}</TableCell>
				  <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins",  align:"center" }} align="center">{pack.Price}</TableCell>
				  {Role === "Admin" && (
					<TableCell>
					  <Button
						variant="contained"
						sx={{ backgroundColor: '#23395d', borderRadius: "7px", color: 'white' }}
						startIcon={<EditIcon sx={{ color: 'white' }} />}
						onClick={() => Modify(pack)}
					  >
						Modify
					  </Button>
					</TableCell>
				  )}
				  {Role === "Admin" && (
					<TableCell>
					  <Button
						variant="contained"
						sx={{ backgroundColor: 'darkred', borderRadius: "7px", color: 'white' }}
						startIcon={<DeleteIcon sx={{ color: 'white' }} />}
						onClick={() => handleClickOpen(pack.packageID)}
					  >
						Delete
					  </Button>
					</TableCell>
				  )}
				</TableRow>
			  ))}
			</TableBody>
		  </Table>
		</TableContainer>
	  </Box>
	)
}


export default DietPage