import { useEffect } from "react";
import "../styles/AllusersPage.css"
import { useState } from "react";
import Multiselect from 'multiselect-react-dropdown';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import * as React from 'react';
import TextField from '@mui/material/TextField';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchIcon from '@mui/icons-material/Search';
import RestoreIcon from '@mui/icons-material/Restore';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MailLockIcon from '@mui/icons-material/MailLock';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../components/navbar";
import Button  from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import { DialogContent as DialogContentJOI } from '@mui/joy';
import { DialogActions as DialogActionsJOI } from '@mui/joy';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { motion } from 'framer-motion';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import LoadingButton from '@mui/lab/LoadingButton';




function AllUsersPage() {
    const API = process.env.REACT_APP_API

    const [systemUsers, setSystemUsers] = useState([])
    const [isLoading, setIsLoading] = useState()
    const [query, setQuery] = useState("")
    const [visible, setVisible] = useState(true)
    const [selected, onSelect] = useState([])
    const [selectedRoles, handleSelectedRoles] = useState([])
    const [userID, setUserID] = useState()
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [restoureModalOpen, setRestoreModalOpen] = useState(false);
    const [emailModalopen, emailModalsetOpen] = React.useState(false);


    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [rerender, setrerender] = useState(false)
    const [userModify, setUserModify] = useState()
    const [updatedName, setUpdatedName] = useState()
    const [updatedadNationalID, setUpdatedNationalID] = useState()
    const [updateQualification, setUpdateQualification] = useState()
    const [updatedAddress, setUpdatedAddress] = useState()
    const [updatedPhone, setUpdatedPhone] = useState()
    const [updatedSecPhone, setUpdatedSecPhone] = useState()
    const [updatedPhoneID, SetUpdatedPhoneID] = useState()
    const [DeleteUserOpen, setDeleteUserOpen] = React.useState(false);
    const [deleteUserID, setDeleteduserID] = useState()
    const [restoredID, setRestoredID] = useState()

    const role = localStorage.getItem("Roles")

    const [updatedRealName, setupdatedRealName] = useState()
    const [value, setValue] = React.useState('1');

    const [loading, setLoading] = useState(false)


    function EmailPasswordModal() {
        return (
            <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={emailModalopen}
            onClose={() => emailModalsetOpen(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Sheet
              variant="outlined"
              sx={{
                maxWidth: 500,
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
              }}
            >
              {/* Modal Title */}
              <Typography fontWeight="bold" fontFamily="poppins">
                {updatedName}
              </Typography>
             
      
              {/* Animated Icon with Framer Motion */}
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
              >
                <MailLockIcon
                  sx={{
                    fontSize: '250px',
                    color: '#f7a41d',
                    marginBottom: 2, // Add space below the icon
                  }}
                />
              </motion.div>
      
              {/* Send Button */}
              <LoadingButton  loading={loading} onClick={(e) => emailPassword(e)}
                sx={{
                  width: '100%',
                  backgroundColor: '#f7a41d',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#f7a41d',
                  },
                }} >
        Send
      </LoadingButton>
           
            </Sheet>
          </Modal>
        );
      }
    

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDeleteUserOpen = () => {
        setDeleteUserOpen(true);
    };

    const handleDeleteUserClose = () => {
        setModalOpen(false);
    };

    function DeleteUser(user) {
        setDeleteduserID(user)
        setModalOpen(true)
    }



    function RestoreUser(user) {
        setDeleteduserID(user)
        setRestoreModalOpen(true)
    }
    function handleRolesUpdate(e) {
        e.preventDefault()
        fetch(`${API}/changeUserRole`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'userID': userID,
                'Roles': selectedRoles,
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: 2000


                    });
                    setrerender(!rerender)
                    handleClose()
                } else {
                    toast.error(`Something went wrong`, {
                        position: "top-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: 2000


                    });
                }
            })
    }


    function emailPassword(e) {
        e.preventDefault()
        setLoading(true)
        fetch(`${API}/emailpassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'username': updatedName,
                'userID': userID,

            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: 2000


                    });
                    setLoading(false)
                    setrerender(!rerender)
                    emailModalsetOpen(false)

                } else  {
                    toast.error(`Something went wrong`, {
                        position: "top-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: 2000


                    });
                }
            })
    }


    function handleUpdateInfo(e) {
        e.preventDefault()
        fetch(`${API}/updateUserInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'Name': updatedName,
                "realName": updatedRealName,
                'NationalID': updatedadNationalID,
                'Address': updatedAddress,
                "Phone": updatedPhone,
                "SecPhone": updatedSecPhone,
                "UserID": updatedPhoneID,
                "qualification": updateQualification,



            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: 2000

                    });
                    setrerender(!rerender)
                    handleClickCloseUserModify()


                }
            })
    }



    function handleDeleteUser(e) {
        e.preventDefault()
        fetch(`${API}/deleteUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "UserID": deleteUserID
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: 2000

                    });
                    setrerender(!rerender)
                    handleDeleteUserClose()
                    setRestoreModalOpen(false)



                }
            })
    }





    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleChange = (event) => {
        handleSelectedRoles(event.target.value)

        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleClickOpenUserModify = () => {
        setUserModify(true);
    };

    const handleClickCloseUserModify = () => {
        setUserModify(false);
    };








    const Roles = {
        1: "Admin",
        2: "General manager",
        3: "Supervisor",
        4: "Trainer",
        5: "Receptionist"

    }
    const optionss = [
        'Admin',
        'General Manager',
        'Supervisor',
        'Trainer',
        'Receptionist',

    ];


    useEffect(function () {
        async function fetchUsersData() {
            try {
                setIsLoading(true)
                const res = await fetch(`${API}/getAllUsers`)
                const data = await res.json()
                setSystemUsers(data.users)
            } catch (e) {
                console.log(e)
            } finally {
                setIsLoading(false)
            }
        } fetchUsersData()
    }, [rerender])



    function updateUserInfo(name, address, phone, secPhone, nationalID, userID, quali, real) {

        setUpdatedName(name)
        console.log(real)
        setUpdatedAddress(address)

        setUpdatedPhone(phone)
        setUpdatedSecPhone(secPhone)
        setUpdatedNationalID(nationalID)
        SetUpdatedPhoneID(userID)
        setUpdateQualification(quali)
        setupdatedRealName(real)

        handleClickOpenUserModify()

    }

    return (
        <div className="allusers-page">
            <EmailPasswordModal />

<Navbar />

<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContentJOI>
            Are you sure you want to  delete this user?
          </DialogContentJOI>
          <DialogActionsJOI>
            <Button variant="solid" color="danger" onClick={handleDeleteUser}>
             Delete
            </Button>
            <Button variant="plain" color="neutral" onClick={ handleDeleteUserClose}>
              Cancel
            </Button>
          </DialogActionsJOI>
        </ModalDialog>
      </Modal>


      
<Modal open={restoureModalOpen} onClose={() => setRestoreModalOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContentJOI>
            Are you sure you want to restore this user?
          </DialogContentJOI>
          <DialogActionsJOI>
            <Button variant="solid"  sx={{backgroundColor:"#72bf6a"}} onClick={handleDeleteUser}>
             Restore
            </Button>
            <Button variant="plain" backgroundColor="neutral" onClick={ handleDeleteUserClose}>
              Cancel
            </Button>
          </DialogActionsJOI>
        </ModalDialog>
      </Modal>
            <Dialog
                fullScreen={fullScreen}
                open={DeleteUserOpen}
                onClose={handleDeleteUserClose}
                aria-labelledby="responsive-dialog-title"
            >

                <DialogContent>
                    <DialogContentText>
                        Are you Sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDeleteUserClose}>
                        cancdel
                    </Button>
                    <Button onClick={handleDeleteUser} autoFocus>
                        confirm
                    </Button>
                </DialogActions>
            </Dialog>





            <section class="intro">
                <ToastContainer
                    position="top-center"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogContent>

                        <Box
                    
                        onSubmit={(e) => handleRolesUpdate(e)} 
                            component="form"
                            sx={{
                                display: 'block',
                                m: 'auto',
                                backgroundColor:"white",
                                height:"400px",
                                fontFamily:"poppins"
                            }}
                        >
                            <Select
                            required
                            sx={{width:"250px"}}
                                multiple
                                displayEmpty
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <strong>Select roles</strong>;
                                    }

                                    return selected.join(', ');
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem disabled value="">
                                </MenuItem>
                                {optionss.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, personName, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <button type="submit" style={{ textAlign: "center", width: "200px", height: "45px", alignItems: "center", backgroundColor: "#152238", marginTop: "100px" }}>Confirm</button>

                        </Box>
                    </DialogContent>
                  
                </Dialog>










                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={userModify}
                    onClose={handleClickCloseUserModify}
                >
                    <DialogContent>

                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'block',
                                m: 'auto',
                            }}
                        >
                            <InputLabel style={{ marginBottom: "10px", fontWeight: "Bold" }} id="demo-multiple-name-label">Change user info</InputLabel>



                            <TextField onChange={(e) => setupdatedRealName(e.target.value)} sx={{ backgroundColor: "white", margin: "15px" }} value={updatedRealName} id="outlined-basic" label="Name" variant="outlined" /> <br />
                            <TextField onChange={(e) => setUpdatedName(e.target.value)} sx={{ backgroundColor: "white", margin: "15px" }} value={updatedName} id="outlined-basic" label="Username" variant="outlined" /> <br />

                            <TextField onChange={(e) => setUpdatedNationalID(e.target.value)} value={updatedadNationalID} id="outlined-basic" label="National ID" variant="outlined" sx={{ backgroundColor: "white", margin: "15px" }} /> <br />
                            <TextField onChange={(e) => setUpdateQualification(e.target.value)} value={updateQualification} id="outlined-basic" label="Qualification" variant="outlined" sx={{ backgroundColor: "white", margin: "15px" }} /> <br />

                            <TextField onChange={(e) => setUpdatedAddress(e.target.value)} value={updatedAddress} id="outlined-basic" label="Address" variant="outlined" sx={{ backgroundColor: "white", margin: "15px" }} /> <br />
                            <TextField onChange={(e) => setUpdatedPhone(e.target.value)} value={updatedPhone} id="outlined-basic" label="Phone" variant="outlined" sx={{ backgroundColor: "white", margin: "15px" }} /><br />
                            <TextField onChange={(e) => setUpdatedSecPhone(e.target.value)} value={updatedSecPhone} id="outlined-basic" label="Second Phone" variant="outlined" sx={{ backgroundColor: "white", margin: "15px" }} /> <br />

                            <button onClick={(e) => handleUpdateInfo(e)} style={{ textAlign: "center", width: "200px", height: "45px", alignItems: "center", backgroundColor: "#152238", marginTop: "100px" }}>Submit</button>



                            {/* <button onClick={(e) => handleRolesUpdate(e)} style={{ textAlign: "center", width: "200px", height: "45px", alignItems: "center", backgroundColor: "#152238", marginTop: "100px" }}>Submit</button> */}

                        </Box>
                    </DialogContent>
                   
                </Dialog>


                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "fit-content", margin: "auto" }}>
                            <TabList sx={{ fontWeight: "bold" }} onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab sx={{ fontWeight: "bold" }} label="Active" value="1" />
                                <Tab
                                    sx={{ fontWeight: "bold" }}

                                    label="Deleted"
                                    value="2"
                                />
                            </TabList>
                        </Box>

                        <TabPanel value="1">

                            <div style={{ padding: "5px", margin: "20px auto", height: "35px", marginBottom: "10px", width: "200px", display: "flex", borderRadius: "20px", justifyContent: "space-between", border: "1px solid #cccccc", alignItems: "center" }} className='search-container'>
                                <input className='search-input' onChange={(e) => { setQuery(e.target.value) }} style={{ border: "none", borderRadius: "20px", alignItems: "center", textAlign: "center", height: "30px", fontSize: "14px", width: "150px", marginBottom: "0", marginLeft: "0", marginRight: "0", backgroundColor: "none" }} placeholder="Search Name" />

                                <span>  <SearchIcon sx={{ backgroundColor: "white", width: "25px", right: "0", height: "70%", color: "black", borderRadius: "20px" }} /></span>

                            </div>
                            <div class="bg-image h-100" style={{ backgroundColor: "#f5f7fa", fontSize: "14px" }}>
                                <TableContainer sx={{}} >
                                    <Table sx={{ minWidth: "70%", width: "70%", margin: "auto", fontWeight: "bold", fontFamily: "poppins", borderRadius: "10px", border: "2px solid #e4e4e4" }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center"> Name</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Username</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Address</TableCell>

                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">National ID</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Qualification</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Hiring Date</TableCell>

                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Phone</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Second Phone</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Role</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center"></TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4" }} align="center"></TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4" }} align="center"></TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4" }} align="center"></TableCell>




                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {systemUsers?.filter(m => {
                                                return (
                                                    m.realName?.toLowerCase().includes(query.toLowerCase()) && m.status === "active"
                                                )
                                            }).map((user) => (
                                                <TableRow
                                                    key={user.realName}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.realName}</TableCell>

                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.FullName} </TableCell>

                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.address}</TableCell>

                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.NationalID}</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.qualifications}</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.hireDate}</TableCell>

                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.phone}</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.secondPhone}</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.Role}</TableCell>

                                                    {role === "Admin"?(                                                    <TableCell sx={{ fontWeight: "bold" }} align="center"><span style={{backgroundColor:"#23395d", height:"45px", width:"45px",padding:"8px", borderRadius:"4px"}} ><ManageAccountsIcon onClick={() => { setUserID(user.userID); handleClickOpen() }} sx={{ cursor: "pointer", color: "white", borderRadius: "2px" }}/></span></TableCell>
):("")}

{role === "Admin"?(                                                    <TableCell sx={{ fontWeight: "bold" }} align="center"> <span  onClick={() => DeleteUser(user.userID)} style={{backgroundColor:"darkred", height:"35px", width:"35px",padding:"8px", borderRadius:"4px"}}> <  DeleteIcon sx={{ cursor: "pointer", color: "white", borderRadius: "2px" }}  /></span></TableCell>
):("")}


                                                {role === "Admin"?(    <TableCell sx={{ fontWeight: "bold" }} align="center">
                                                        <span onClick={() => updateUserInfo(user.FullName, user.address, user.phone, user.secondPhone, user.NationalID, user.userID, user.qualifications, user.realName)} style={{backgroundColor:"#152238", height:"35px", width:"35px",padding:"8px", borderRadius:"4px"}}>  
                                                        <EditIcon sx={{ cursor: "pointer", color: "white", borderRadius: "2px" }}
                                                            
                                                        />
                                                        </span>

                                                    </TableCell>):("")}


                                                   {role === "Admin"?( 
                                                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                                                        <span onClick={() => {
                                                    setUserID(user.userID);
          setUpdatedName(user.FullName); // Set the user's full name
          emailModalsetOpen(true); // Open the modal
        }} style={{backgroundColor:"#152238", height:"35px", width:"35px",padding:"8px", borderRadius:"4px"}}>  
                                                        <MailLockIcon sx={{ cursor: "pointer", color: "white", borderRadius: "2px" }}
                                                            
                                                        />
                                                        </span>

                                                    </TableCell>
):("")}







                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </TabPanel>
                        <TabPanel value="2">
                        <div style={{ padding: "5px", margin: "20px auto", height: "35px", marginBottom: "10px", width: "200px", display: "flex", borderRadius: "20px", justifyContent: "space-between", border: "1px solid #cccccc", alignItems: "center" }} className='search-container'>
                                <input className='search-input' onChange={(e) => { setQuery(e.target.value) }} style={{ border: "none", borderRadius: "20px", alignItems: "center", textAlign: "center", height: "30px", fontSize: "14px", width: "150px", marginBottom: "0", marginLeft: "0", marginRight: "0", backgroundColor: "none" }} placeholder="Search Name" />

                                <span>  <SearchIcon sx={{ backgroundColor: "white", width: "25px", right: "0", height: "70%", color: "black", borderRadius: "20px" }} /></span>

                            </div>
                        <TableContainer sx={{}} >
                                    <Table sx={{ minWidth: "70%", width: "70%", margin: "auto", fontWeight: "bold", fontFamily: "poppins", borderRadius: "10px", border: "2px solid #e4e4e4" }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center"> Name</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Username</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Address</TableCell>

                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">National ID</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Qualification</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Hiring Date</TableCell>

                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Phone</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Second Phone</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center">Role</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4", fontFamily: "poppins" }} align="center"></TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "15px", backgroundColor: "#e4e4e4" }} align="center"></TableCell>

                                         


                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {systemUsers?.filter(m => {
                                                return (
                                                    m.realName?.toLowerCase().includes(query.toLowerCase()) && m.status === "nonactive"
                                                )
                                            }).map((user) => (
                                                <TableRow
                                                    key={user.realName}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.realName}</TableCell>

                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.FullName} </TableCell>

                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.address}</TableCell>

                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.NationalID}</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.qualifications}</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.hireDate}</TableCell>

                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.phone}</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold", fontFamily: "poppins" }} align="center">{user.secondPhone}</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold" , fontFamily:"poppins"}} align="center">{user.Role}</TableCell>
                                                    {role === "Admin"?(                                                    <TableCell sx={{ fontWeight: "bold" }} align="center"> <span  onClick={() => RestoreUser(user.userID)} style={{backgroundColor:"#72bf6a", height:"35px", width:"35px",padding:"8px", borderRadius:"4px"}}> <  RestoreIcon  sx={{ cursor: "pointer", color: "white", borderRadius: "2px" }}  /></span></TableCell>
):("")}
                                                    
                                                    {role === "Admin"?(
                                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                                        <span onClick={() => {
                                                    setUserID(user.userID);
          setUpdatedName(user.FullName); // Set the user's full name
          emailModalsetOpen(true); // Open the modal
        }} style={{backgroundColor:"#152238", height:"35px", width:"35px",padding:"8px", borderRadius:"4px"}}>  
                                                        <MailLockIcon sx={{ cursor: "pointer", color: "white", borderRadius: "2px" }}
                                                            
                                                        />
                                                        </span>

                                                    </TableCell>


                                                    ):("")}







                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                        </TabPanel>
                    </TabContext>
                </Box>

            </section >
        </div >
    )
}


export default AllUsersPage;