// import React, { useState, useEffect } from 'react';
// import { DatePicker } from 'antd';
// import { Button, Box, CircularProgress, Typography, Paper, Container } from '@mui/material';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import PaidIcon from '@mui/icons-material/Paid';
// import SearchIcon from '@mui/icons-material/Search';
// import { styled } from '@mui/material/styles';
// import moment from 'moment-timezone';
// import { Card, CardContent } from '@mui/material';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';


// const StyledTableCell = styled(TableCell)(({ theme }) => ({
// 	[`&.${tableCellClasses.head}`]: {
// 		backgroundColor: theme.palette.grey[200],
// 		color: theme.palette.text.primary,
// 		fontWeight: 'bold',
// 	},
// 	[`&.${tableCellClasses.body}`]: {
// 		fontSize: 14,
// 	},
// }));

// const API = process.env.REACT_APP_API;

// function DietFinance() {
// 	const [finance, setFinanceData] = useState([]);
// 	const [loading, setLoading] = useState(false);
// 	const [startDate, setStartDate] = useState(moment().tz('Africa/Cairo').format('YYYY-MM-DD'));
// 	const [endDate, setEndDate] = useState(moment().tz('Africa/Cairo').format('YYYY-MM-DD'));
// 	const [branches, setBranches] = useState([]);
// 	const [selectedBranch, setSelectedBranch] = useState('All');
// 	const [trainerName, setTrainerName] = useState('');
// 	useEffect(() => {
// 		async function fetchBranches() {
// 			try {
// 				const res = await fetch(`${API}/branches`);
// 				const data = await res.json();
// 				setBranches(data.branches);
// 			} catch (error) {
// 				console.error(error);
// 			}
// 		}
// 		fetchBranches();
// 	}, []);

// 	const handleFilter = async () => {
// 		setLoading(true);
// 		try {
// 			const res = await fetch(`${API}/Dietfinance?startDate=${startDate}&endDate=${endDate}&trainer=${trainerName}&branch=${selectedBranch}`);
// 			const data = await res.json();
// 			setFinanceData(data.result);
// 		} catch (error) {
// 			console.error(error);
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	const sumPaid = finance.reduce((acc, item) => acc + item.PaidValue, 0);

// 	return (
// 		<div className="finance-page-container" style={{ padding: '20px' }}>
// 			<Typography  sx={{fontWeight:"bold", color:"black", fontSize:"40px"}} align="center" gutterBottom>
// 				Diet Finance
// 			</Typography>
// 			<Container 
//             sx={{
//                 border: '3px solid #e4e4e4', // Set border color
//                 padding: '20px',
//                 borderRadius: '8px',
//                 backgroundColor: "#e8ebf3",
// 				marginBottom:"20px" // Set background color if needed
//             }}
//         >
//             <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
//                 <DatePicker
//                     style={{ border: "1px solid #e4e4e4", marginRight:"5px" }}
//                     placeholder="From"
//                     format="YYYY-MM-DD"
//                     onChange={(date, dateString) => setStartDate(dateString)}
//                 />
//                 <DatePicker
//                     style={{ border: "1px solid #e4e4e4" }}
//                     placeholder="To"
//                     format="YYYY-MM-DD"
//                     onChange={(date, dateString) => setEndDate(dateString)}
//                 />
//             </div>

//             <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: '20px' }}>
//                 <select
//                     onChange={(e) => setSelectedBranch(e.target.value)}
//                     style={{
//                         margin: '10px 0', 
//                         border: "1px solid #e4e4e4",
//                         borderRadius: "5px",
//                         height: "32px",
//                         textAlign: "center",
// 						fontWeight:"bold",
// 						fontFamily:"poppins"
//                     }}
//                 >
//                     <option value="All">All Branches</option>
//                     {branches.map(branch => (
//                         <option key={branch.id} value={branch.name}>{branch.name}</option>
//                     ))}
//                 </select>
//                 <Button
//                     variant="contained"
//                     onClick={handleFilter}
//                     startIcon={<SearchIcon style={{ color: "white" }} />}
//                     sx={{
//                         backgroundColor: '#027fb4',
//                         color: 'white',
//                         borderRadius: '6px',
//                         fontWeight: "bold",
// 						marginTop:"10px"
//                     }}
//                 >
//                     Search
//                 </Button>
//             </div>
//         </Container>
// 		<Container
		
// 		sx={{
// 			border: '3px solid #e4e4e4', // Set border color
// 			padding: '10px',
// 			borderRadius: '8px',
// 			backgroundColor: '#e8ebf3',
// 			marginBottom:"20px", // Set background color if needed
// 			height:"100px",
// 			alignItems:"center",
// 			textAlign:"center"
// 		}}
// 		>
			
// 		<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>


// 		<Paper
// 						elevation={10}
// 						sx={{
// 							padding: '10px',
// 							background: 'linear-gradient(to right, #025d82, #0094d4)',
// 							borderRadius: '10px',
// 							display: 'block',
// 							textAlign: "center",
// 							alignItems: 'center',
// 							fontFamily: "poppins",
// 							color: "white",
// 							width: "250px",
// 							height: "90px",
// 							display: "flex",
// 							justifyContent: "center",
// 							marginRight: "13px",

// 							boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
// 						}}
// 					>


// 						<Box sx={{ display: "block", width: "100%", }}>
// 							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
// 								<AccountCircleIcon sx={{ fontSize: "30px" }} />

// 							</Box>
// 							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "30px" }}>
// 								{finance?.length}
// 							</Typography>
// 						</Box>
// 					</Paper>
			
// 				<Paper
// 					elevation={10}
// 					sx={{
// 						padding: '10px',
// 							background: 'linear-gradient(to right, #025d82, #0094d4)',
// 							borderRadius: '10px',
// 							display: 'block',
// 							textAlign: "center",
// 							alignItems: 'center',
// 							fontFamily: "poppins",
// 							color: "white",
// 							width: "250px",
// 							height: "90px",
// 							display: "flex",
// 							justifyContent: "center",
// 							marginRight: "13px",

// 							boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
// 					}}
// 				>

// <Box sx={{ display: "block", width: "100%", }}>

// 					<Box sx={{ display: "flex",width:"fit-content", flexDirection: "row", alignItems: "center", textAlign: "center" , margin:"auto"}}>
// 						<PaidIcon style={{ marginRight: '8px' }} />
// 						<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
// 							Total Paid
// 						</Typography>
// 					</Box>
// 					<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
// 						{sumPaid} EGP
// 					</Typography>
// 					</Box>
// 				</Paper>
// 			</div>
// 		</Container>


// 			{loading ? (
// 				<Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px auto' }}>
// 					<CircularProgress />
// 				</Box>
// 			) : (
// 				<Container sx={{
// 					backgroundColor: '#e8ebf3',
// 					padding:"10px"

// 				}}>
// 					<TableContainer component={Paper}>
// 					<Table>
// 						<TableHead>
// 							<TableRow>
// 								<StyledTableCell align="center">Customer Name</StyledTableCell>
// 								<StyledTableCell align="center">Phone</StyledTableCell>
// 								<StyledTableCell align="center">Paid Value</StyledTableCell>
// 								<StyledTableCell align="center">Pay Date</StyledTableCell>
// 								<StyledTableCell align="center">Category</StyledTableCell>
// 								<StyledTableCell align="center">Trainer</StyledTableCell>
// 								<StyledTableCell align="center">Branch</StyledTableCell>
// 							</TableRow>
// 						</TableHead>
// 						<TableBody>
// 							{finance.map((row) => (
// 								<TableRow key={row.id}>
// 									<StyledTableCell align="center">{row.Fullname}</StyledTableCell>
// 									<StyledTableCell align="center">{row.phone}</StyledTableCell>
// 									<StyledTableCell align="center">{row.PaidValue}</StyledTableCell>
// 									<StyledTableCell align="center">{row.PayDate}</StyledTableCell>
// 									<StyledTableCell align="center">{row.Category}</StyledTableCell>
// 									<StyledTableCell align="center">{row.Trainer}</StyledTableCell>
// 									<StyledTableCell align="center">{row.Branch}</StyledTableCell>
// 								</TableRow>
// 							))}
// 						</TableBody>
// 					</Table>
// 				</TableContainer>
// 				</Container>
// 			)}
// 		</div>
// 	);
// }

// export default DietFinance;


import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';

import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Table } from 'antd';
import * as XLSX from 'xlsx'; 
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));



const API = process.env.REACT_APP_API

function GymFinance() {
	const [filtered, setFiltered] = useState("All")
	const [selectedBranch, setSelectedBranch] = useState("All")
	const [branches, setBranches] = useState()
	const [branchesLoading, setBranchesLoading] = useState()
	const [loading, setLoading] = useState(true)
	const [financeDage, setFinanceData] = useState([])
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
	const [rednder, setRerender] = useState()
	const [trainer, setTrainer] = useState()
	const [trainersLoading, setTrainersLoading] = useState()
	const [trainerName, settrainerName] = useState("All")
	const [gymPackages, setGymPackages] = useState([])
	const [selectedPackage, setSelectedPackage] = useState("All")
	const [gender, setSelectedGender] = useState("All")
	const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

	const [gymPackagesLoading, setGymPackagesLoading] = useState(true)
	const [ageFrom, setAgeFrom] = useState("All")
	const [ageTo, setAgeTo] = useState("All")
	const [searched, setSearched] = useState(false)


	const [customerID, setCustomerID] = useState("")
	const [tableParams, setTableParams] = useState({
	  pagination: {
		current: 1,
		pageSize: 10,
	  },
	});

	const fetchData = () => {
	  setLoading(true);
	  fetch(`${API}/Dietfinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`)	  
	  .then((res) => res.json())

	  .then(( results ) => {
		  setFinanceData(results.result);
		  setLoading(false);
		  setTableParams({
			...tableParams,
			pagination: {
				...tableParams.pagination,
				total: results.result?.length || 0,
				// 200 is mock data, you should read it from server
				// total: data.totalCount,
			},
		});
		});
	};


	useEffect(fetchData, []);
	const handleTableChange = (pagination, filters, sorter) => {
		const updatedParams = {
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		};

		setTableParams(updatedParams);

	};

	const columns = [
		 {
    title: 'Customer ID',
    dataIndex: 'CustomerID',
    sorter: (a, b) => a.CustomerID - b.CustomerID,
  },
		{
		  title: 'Full name',
		  dataIndex: 'Fullname',
		  align:"center"

		  
	
		},

		{
			title: 'Phone',
			dataIndex: 'Phone',
			align:"center"

	  
		  },

		  {
			title: 'Age',
			dataIndex: 'customerAge',
			sorter: (a, b) => a.customerAge - b.customerAge,
			align:"center"


	  
		  },


		  {
			title: 'National ID',
			dataIndex: 'NationalID',
			align:"center"

	  
		  },
		//   PayDate	Category	Dep	Trainer	Branch
		  {
			title: 'Paid Value',
			dataIndex: 'PaidValue',
			sorter: (a, b) => a.PaidValue - b.PaidValue,
			align:"center"

	  
		  },
		  {
			title: 'Remianing Value',
			dataIndex: 'Remaining',
			sorter: (a, b) => a.Remaining - b.Remaining,
			align:"center"

		  },
		  {
			title: 'Pay Date',
			dataIndex: 'PayDate',
			sorter: (a, b) => new Date(a.PayDate) - new Date(b.PayDate),			
			align:"center"

	  
		  },

		  {
			title: 'Category',
			dataIndex: 'Category',
			align:"center"

		  },

		 

		  {
			title: 'Trainer',
			dataIndex: 'Trainer',
			align:"center",
			sorter: {
				compare: (a, b) =>sorter( a.Trainer , b.Trainer),
			},
	  
		  },

		  {
			title: 'Branch',
			dataIndex: 'Branch',
			align:"center",
			sorter: {
				compare: (a, b) =>sorter( a.Branch , b.Branch),
			},

		  },

		  {
			title: 'Package',
			dataIndex: 'package',
			align:"center",
			sorter: {
				compare: (a, b) =>sorter( a.package , b.package),
			},


		  },
	
	  ];




	function onChange(date, dateString) {
		console.log(dateString)
		setDate(dateString)
		console.log(startDate)
		setFiltered("filtered")

	}




	function onEndDatehange(date, dateString) {
		console.log(dateString)
		setEnDate(dateString)
		console.log(endDate)
		setFiltered("filtered")


	}

	useEffect(function () {
		async function getGymPackages() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/dietPackages`)
				const data = await res.json()
				setGymPackages(data.data)
			} catch (e) {
				console.log(e)
			} finally {
				setGymPackagesLoading(false)
			}
		} getGymPackages()

	}, [])









    const handleExport = () => {
        // Create a worksheet from the dataSource
        const ws = XLSX.utils.json_to_sheet(financeDage);
    
        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Add worksheet to workbook
    
        // Generate the Excel file and prompt download
        XLSX.writeFile(wb, 'TableData.xlsx');
      };


	useEffect(function () {
		async function fetchBranches() {
			try {
				setBranchesLoading(true)
				const res = await fetch(`${API}/branches`)
				const data = await res.json()
				setBranches(data.branches)

			} catch (e) {
				console.log(e)
			} finally {
				setBranchesLoading(false)
			}
		} fetchBranches()
	}, [])

	useEffect(function () {
		async function getTrainers() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				setTrainer(data.result)
				console.log(data.result)
				console.log(data)
			} catch (e) {
				console.log(e)
			} finally {
				setTrainersLoading(false)
			}
		} getTrainers()

	}, [])





	async function handleFilter() {
		try {
			setLoading(true)
	const res = await fetch(`${API}/Dietfinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`);
const data = await res.json()
			setFinanceData(data.result)
			setSearched(true)

		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)


		}
	}








	{if ( branchesLoading || trainersLoading || gymPackagesLoading )return <CircularProgress sx={{display:"flex", width:"fit-content", top:"50%",left:"50%", position:"absolute"}} />}

	const sumPaid = financeDage?.reduce((acc, item) => acc + item.PaidValue, 0);


	return (

		<div style={{ backgroundColor: "#eaedf4", height: "100%", width: "100%" }} className="finance-page-container">
			<h3 style={{ margin: "0 auto", width: "200px" }}>Diet Finance</h3>

			<Container
				sx={{
					padding: '20px',
					borderRadius: '8px',
					backgroundColor: "white",
					boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
					marginBottom: "20px",


				}}
			>

				<div style={{ borderRadius: "8px", width: "40%", margin: "20px auto", display: "flex", justifyContent: "space-between" }} className="datepicker-container">
					<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='From' format={"YYYY-MM-DD"} onChange={onChange} />
					<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='To' format={"YYYY-MM-DD"} onChange={onEndDatehange} />

				</div>
		
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: '20px', marginTop: "40px" }}>
					<div style={{ display: "flex", margin: "0 auto", width: "auto", flexDirection: "row", alignItems: "flex-start", textAlign: "center", height: "80px" }}>
						{['Trainer', 'Branch', 'Gender', 'Package'].map((placeholder, index) => (
							<div key={index} style={{ position: "relative", marginLeft: index > 0 ? "5px" : "0" }}>
								<label style={{
									position: "absolute",
									top: "-20px", // Position the label higher
									fontSize: "12px",
									color: "#777",
									padding: "0 5px",
									zIndex: 1,
								}}>
									{placeholder}
								</label>
								<select
									onChange={index === 0 ? (e) => settrainerName(e.target.value) :
										index === 1 ? (e) => setSelectedBranch(e.target.value) :
											index === 2 ? (e) => setSelectedGender(e.target.value) :
												(e) => setSelectedPackage(e.target.value)}
									style={{
										width: index === 0 ? "170px" : "150px",
										height: "40px",
										border: "none",
										textAlign: "center",
										alignItems: "center",
										marginTop: "10px", // Add space below the label
										backgroundColor: "#eaedf4",
										borderRadius: "8px",
									}}
									disabled={index === 2 && customerID !== ""}
								>
									<option value="All" >All</option>
									{index === 0 && trainer?.map(t => (
										<option key={t.fullName} value={t.fullName}>{t.fullName}</option>
									))}
									{index === 1 && (
										<>
											{branches?.map(branch => (
												<option key={branch.name} value={branch.name}>{branch.name}</option>
											))}
										</>
									)}
									{index === 2 && (
										<>
											<option value="Male">Male</option>
											<option value="Female">Female</option>
										</>
									)}
									{/* {index === 3 &&  (
											<>
												<option value="Package1">Package 1</option>
												<option value="Package2">Package 2</option>
											</>
										)} */}

									{index === 3 && gymPackages?.map(pack => (
										<option key={pack.Name} value={pack.Name}>{pack.Name}</option>
									))}
								</select>
							</div>
						))}
					</div>
					<div style={{
						position: "relative", marginLeft: "5",
					}}>

						<div style={{ display: "flex", flexDirection: "row", marginBottom: "15px", }}>
							<div style={{ marginRight: "10px" }}>	<label style={{
								position: "absolute",
								top: "-20px", // Position the label higher
								fontSize: "12px",
								color: "#777",
								zIndex: 1,
								marginBottom: "5px",
								width: "100px",
							}}>
								Age from
							</label>

								<select
																disabled={ customerID !== ""}

								onChange={(e) => setAgeFrom(e.target.value)} style={{
									width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
								}}>

									{Array.from({ length: 80 }, (_, index) => (
										<option value={index}>
											{index}
										</option>

									))}
								</select></div>
							<div>	<label style={{
								position: "absolute",
								top: "-20px", // Position the label higher
								fontSize: "12px",
								color: "#777",
								zIndex: 1,
								marginBottom: "5px",
								width: "100px",


							}}>
								Age to
							</label>

								<select 
																disabled={ customerID !== ""}

								onChange={(e) => setAgeTo(e.target.value)} style={{
									width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
								}}>

									{Array.from({ length: 80 }, (_, index) => (
										<option value={index}>
											{index}
										</option>

									))}
								</select></div>
						</div>
					</div>
					<input onChange={(e)=>setCustomerID(e.target.value)} style={{width:"200px", height:"40px", backgroundColor:"#eaedf4", border:"none" , fontSize:"14px", textAlign:"center", margin:"10px"}} placeholder='Customer ID' type = "text" />

					<Button
						variant="contained"
						onClick={handleFilter}
						startIcon={<SearchIcon style={{ color: "black" }} />}
						sx={{
							":hover": {
								backgroundColor: '#eaedf4',

								boxShadow: 'none',

							},
							backgroundColor: "#d7d9e9",

							color: 'black',
							borderRadius: '6px',
							fontWeight: "bold",
							marginTop: "10px",
							margin: "auto",
							width: "150px",
							fontWeight: "Bold",
							boxShadow: 'none',
						}}
					>
						Search
					</Button>

					{searched ? (
    <lebel style={{marginTop:"10px"}}>
        <strong>{`${gender}`}</strong>
        {` Customers  `}
        <strong>{` between ${ageFrom} years and ${ageTo} years`}</strong>
        {` in `}
        <strong>{`${selectedBranch} branch` }</strong>
        {` within `}
        <strong>{`${startDate} and ${endDate}`}</strong>
        {` with  `}
        <strong>{`${trainerName} `}</strong>
    </lebel>
) : (
    ""
)}
				</div>
			</Container>

			<Container

				sx={{
					padding: '25px',
					padding: '25px',
					borderRadius: '8px',
					marginBottom: "20px", // Set background color if needed
					height: "100px",
					alignItems: "center",
					textAlign: "center",
					justifyContent: "center",
					boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
					height: "180px",
					display: "flex",
					backgroundColor: "white"

				}}
			>
				<Paper
					elevation={0}
					sx={{
						padding: '10px',
						backgroundColor: "#edf0f7",
						border: "1px solid #e4e4e4",
						color: "black",
						borderRadius: '10px',
						display: 'block',
						textAlign: "center",
						alignItems: 'center',
						fontFamily: "poppins",
						width: "250px",
						height: "90px",
						display: "flex",
						justifyContent: "center",
						marginRight: "13px",

					}}
				>


					<Box sx={{ display: "block", width: "100%", }}>
						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
							<AccountCircleIcon sx={{ fontSize: "30px" }} />

						</Box>
						<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "30px" }}>
							{financeDage?.length}
						</Typography>
					</Box>
				</Paper>
				<Paper
					elevation={0}
					sx={{
						padding: '10px',
						backgroundColor: "#edf0f7",
						border: "1px solid #e4e4e4",
						color: "black",
						borderRadius: '10px',
						display: 'block',
						textAlign: "center",
						alignItems: 'center',
						fontFamily: "poppins",
						width: "250px",
						height: "90px",
						display: "flex",
						justifyContent: "center",
						marginRight: "13px",

					}}
				>



					<Box sx={{ display: "block", width: "100%", }}>
						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
							<PaidIcon />
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
								Total
							</Typography>
						</Box>
						<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
							{Number(sumPaid) } EGP
						</Typography>
					</Box>
				 </Paper>

				
			</Container>


			<Container sx={{width:"fit-content", margin:"auto", marginBottom:"10px"
}}>
                <Button
                        variant="contained"
                        onClick={handleExport}
                        startIcon={<InsertDriveFileIcon style={{ color: "black" }} />}
                        sx={{
                            ":hover": {
                                backgroundColor: '#eaedf4',

                                boxShadow: 'none',

                            },
                            backgroundColor: "#d7d9e9",

                            color: 'black',
                            borderRadius: '6px',
                            fontWeight: "bold",
                            marginTop: "10px",
                            margin: "auto",
                            width: "200px",
                            fontWeight: "Bold",
                            boxShadow: 'none',
                        }}
                    >
                        Download Excel
                    </Button>
                </Container>


			{loading ? (<Box sx={{ display: 'flex', margin: "0 auto", width: "10%" }}>
				<CircularProgress />
			</Box>) : (<>
				<Table
      columns={columns}
    //   rowKey={(record) => record.login.uuid}
      dataSource={financeDage}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
    />


			</>


			)




			}

		</div>
	)

}

export default GymFinance