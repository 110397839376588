import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import qs from 'qs';
import { Switch, Link, Route } from 'react-router-dom';
import SiteNav, { ContentGroup } from 'react-site-nav'; // 1. Do this
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import "../styles/attendanceTime.css"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PrimarySearchAppBar from '../components/secNav';
import Navbar from '../components/navbar';
function AttendanceMain() {
	const API = process.env.REACT_APP_API

	const [value, setValue] = React.useState('1');
	const [attendaners, setAttenders] = useState()

	const handleChange = (event, newValue) => {
		setValue(newValue);
	}




	const useStyles = makeStyles({
		tabs: {

			"& .MuiTabs-indicator": {
				backgroundColor: "#187bcd",
				height: 3,
			},
			"& .MuiTab-root.Mui-selected": {
				color: '#187bcd'
			}
		}
	})
	const classes = useStyles();
	return (


		<>
			<Navbar />
			<Box sx={{ width: '90%', typography: 'body1', margin: "50px auto" }}>
				<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <TabList
    className={classes.tabs}
    sx={{
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-around", // Distribute tabs evenly
      flexWrap: "wrap", // Allow tabs to wrap when there isn't enough space
      "@media (max-width: 600px)": {
        flexDirection: "row", // Ensure tabs are horizontal on mobile
        overflowX: "auto", // Make tabs horizontally scrollable if needed
        "-webkit-overflow-scrolling": "touch", // Enable smooth scrolling on iOS
        paddingBottom: "10px", // Add a little padding for better spacing
      },
    }}
    onChange={handleChange}
    aria-label="lab API tabs example"
  >
    <Tab
      sx={{
        margin: "0 auto",
        fontWeight: "bold",
        color: "black",
        padding: "8px 16px", // Reduced padding for smaller tabs
        "@media (max-width: 600px)": {
          fontSize: "14px", // Smaller font size on mobile
        },
      }}
      label="All"
      component={Link}
      to="all"
    />
    <Tab
      sx={{
        margin: "0 auto",
        fontWeight: "bold",
        color: "black",
        padding: "8px 16px", // Reduced padding for smaller tabs
        "@media (max-width: 600px)": {
          fontSize: "14px", // Smaller font size on mobile
        },
      }}
      label="Gym"
      component={Link}
      to="gym"
    />
    <Tab
      sx={{
        margin: "0 auto",
        fontWeight: "bold",
        color: "black",
        padding: "8px 16px", // Reduced padding for smaller tabs
        "@media (max-width: 600px)": {
          fontSize: "14px", // Smaller font size on mobile
        },
      }}
      label="Class"
      component={Link}
      to="class"
    />
    <Tab
      sx={{
        margin: "0 auto",
        fontWeight: "bold",
        color: "black",
        padding: "8px 16px", // Reduced padding for smaller tabs
        "@media (max-width: 600px)": {
          fontSize: "14px", // Smaller font size on mobile
        },
      }}
      label="Academy"
      component={Link}
      to="academy"
    />
    <Tab
      sx={{
        margin: "0 auto",
        fontWeight: "bold",
        color: "black",
        padding: "8px 16px", // Reduced padding for smaller tabs
        "@media (max-width: 600px)": {
          fontSize: "14px", // Smaller font size on mobile
        },
      }}
      label="Clinic"
      component={Link}
      to="clinic"
    />
  </TabList>
</Box>



					<Outlet />


				</TabContext>
			</Box>


		</>

	)
}


export default AttendanceMain