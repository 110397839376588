import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const API = process.env.REACT_APP_API;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => { 
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(() => localStorage.getItem('Token'));
    const navigate = useNavigate();
    const LOGOUT_KEY = 'logout_event';
    const logoutChannel = new BroadcastChannel('logout');
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('Token'));
   const  timeoutDuration =  120000


    const loginAction = (data) => {
        fetch(`${API}/auth/login`, {
            method: 'POST',
            credentials: 'include',  // Ensure cookies are sent

            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(result => {
            if (result.status == "401") {
                toast.error(`${result.message}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else if (result.status == 200) {
                setUser(result.user);
                setToken(result.token);

                // Store token and user data in local storage
                localStorage.setItem('Token', result.token);
                localStorage.setItem('userID', result.userID);
                localStorage.setItem('Roles', result.Role);
                localStorage.setItem('loggedBranch', result.loggedBranch);
                localStorage.setItem('User', result.Fullname);
                localStorage.setItem('tokenExpiry', Date.now() + 86400000 );

                // Navigate to the user dashboard after successful login
                navigate(`/user`);
            } else {
              toast.error(`Something went wrong`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            }
        })
        .catch(error => {
            console.error('Login error:', error);
            toast.error('An unexpected error occurred. Please try again.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        });
    };
    //   const navigate = useNavigate()
    
      useEffect(() => {
        const handleStorageChange = (event) => {
          if (event.key === LOGOUT_KEY) {
            setIsAuthenticated(false); 
          }
        };
    
        window.addEventListener('storage', handleStorageChange);
    
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, []);

   
    // const logout = () => {
    //     logoutChannel.postMessage("Logout")
    //     document.cookie = 'authToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    //     window.location.href = window.location.origin + "/login";
    //     // navigate("/login")
    
    
    
    //   };
    

    const logout = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/logoutCookie`, {
          method: "GET",
          credentials: "include", // Ensure the cookie is included in the request
        });
  
        if (response.ok) {
          
          setIsAuthenticated(false); // Update state to reflect that the user is logged out
          window.location.href = window.location.origin + "/login";
            } else {
          console.error("Logout failed");
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }
    };
    
    
      // const logOutAllTabs = () =>{
      //   logoutChannel.onmessage = () => {
      //   console.log("MMMMMMM")
      //       logout()
      //       logoutChannel.close()
      //   }
      // }
    
     

    return (
        <AuthContext.Provider value={{ user, token, loginAction, logout, timeoutDuration }}>
            {children} {/* Make sure to render children here */}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
