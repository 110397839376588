
// import * as React from 'react';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
// import ReactDeleteRow from 'react-delete-row';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import { useState } from 'react';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { TablePagination } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



const API = process.env.REACT_APP_API
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function CustomerAttanedancePage() {
  const [rerender, setRerender] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [AcademyOpen, setAcademyOpen] = React.useState(false);

  const [ClassOpen, setClassOpen] = React.useState(false);
  const [ClinicOpen, setClinicOpen] = React.useState(false);
  const [clinicPackID, setClinicPackID] = useState()


  const [academyData, setAcadeyData] = React.useState([])
  const [expanded, setExpanded] = React.useState('panel1');
  const role = localStorage.getItem("Roles")




  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleClickAcademyOpen = () => {
    setAcademyOpen(true);
  };

  const handleAcademyClose = () => {
    setAcademyOpen(false);
  };


  const handleClickClassOpen = () => {
    setClassOpen(true);
  };

  const handleClickClinicOpen = () => {
    setClinicOpen(true);
  };

  const handleClinicClose = () => {
    setClinicOpen(false);
  };


  const handleClassClose = () => {
    setClassOpen(false);
  };



  const params = useParams()
  const customerID = params.customerID
  const [loading, setLoading] = React.useState()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);


  const [clinicloading, setClinicLoading] = React.useState()

const [clinicData, setClinicData] = React.useState([])

  const [pageAcademy, setPageAcademy] = React.useState(0);
  const [rowsPerPageAcademy, setRowsPerPageAcademy] = React.useState(5);

  const handleChangePageAcademy = (event, newPage) => {
    setPageAcademy(newPage);
  };

  const handleChangeRowsPerPageAcademy = (event) => {
    setRowsPerPageAcademy(parseInt(event.target.value, 5));
    setPageAcademy(0);
  };

  const [pageClass, setPageClass] = React.useState(0);
  const [rowsPerPageClass, setRowsPerPageClass] = React.useState(5);

  const handleChangePageClass = (event, newPage) => {
    setPageClass(newPage);
  };

  const handleChangeRowsPerPageClass = (event) => {
    setRowsPerPageClass(parseInt(event.target.value, 5));
    setPageClass(0);
  };



  
  const [pageClinic, setPageClinic] = React.useState(0);
  const [rowsPerPageClinic, setRowsPerPageClinic] = React.useState(5);

  const handleChangePageClinic = (event, newPage) => {
    setPageClinic(newPage);
  };

  const handleChangeRowsPerPageClinic= (event) => {
    setRowsPerPageClinic(parseInt(event.target.value, 5));
    setPageClinic(0);
  };





  const [gymAttendance, setGymAttendance] = React.useState([])
  const [cancelSessionID, setCancelSessionID] = React.useState()
  const [sessionDate, setSessionDate] = React.useState()
  const [academyLoading, setAcademyLoading] = React.useState()
  const [gymDateQuery, setGymDateQuerry] = React.useState("")
  const [classData, setClassData] = React.useState([])
  const [classLoading, setClassLoading] = React.useState()


  // gym pagination 



  React.useEffect(function () {
    async function fetchGymDate() {
      try {
        setLoading(true)
        const res = await fetch(`${API}/getGymAttendance/${customerID}`)
        const data = await res.json()
        console.log(data)
        setGymAttendance(data.data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)

      }
    }
    fetchGymDate()


  }, [rerender])


  React.useEffect(function () {
    async function fetchAcademyData() {
      try {
        setAcademyLoading(true)
        const res = await fetch(`${API}/academyAttemdance/${customerID}`)
        const data = await res.json()
        setAcadeyData(data.data)
      } catch (e) {
        console.log(e)
      } finally {
        setAcademyLoading(false)

      }
    }
    fetchAcademyData()

  }, [rerender])


  React.useEffect(function () {
    async function FetchClinicData() {
      try {
        setClinicLoading(true)
        const res = await fetch(`${API}/getClinicAttendance/${customerID}`)
        const data = await res.json()
        setClinicData(data.data)
      } catch (e) {
        console.log(e)
      } finally {
        setClinicLoading(false)

      }
    }
    FetchClinicData()

  }, [rerender])

  React.useEffect(function () {
    async function fetchClassData() {
      try {
        setClassLoading(true)
        const res = await fetch(`${API}/classAttendance/${customerID}`)
        const data = await res.json()
        setClassData(data)
        
      } catch (e) {
        console.log(e)
      } finally {
        setClassLoading(false)

      }
    }
    fetchClassData()

  }, [rerender])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  function handleOnDelete(a) {
    setCancelSessionID(a)
    handleClickOpen()
  }

  function handleOnAcademyDelete(a) {
    setCancelSessionID(a)
    handleClickAcademyOpen()
  }

  function handleOnClassDelete(a) {
    setCancelSessionID(a)
    handleClickClassOpen()
  }

  function handleOnClientDelete(a,b) {
    setCancelSessionID(a)
    setClinicPackID(b)
    handleClickClinicOpen()
  }



  function handleDeleteSession() {
    fetch(`${API}/camcelSession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'sessionID': cancelSessionID,
        'customerID': customerID,

      })
    }).then(response => response.json())
      .then(result => {
        console.log(result.status == 200)
        if (result.status == 200) {
          toast.success(result.Message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      setRerender(!rerender)

    handleClose()
  }


  function handleDeleteClinicSession() {
    fetch(`${API}/cancelClinicSession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'sessionID': cancelSessionID,
        'customerID': customerID,
        "packID":clinicPackID

      })
    }).then(response => response.json())
      .then(result => {
        console.log(result.status == 200)
        if (result.status == 200) {
          toast.success(result.Message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(result.Message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

        }
      })
      setRerender(!rerender)

    handleClinicClose()
  }


  function handleDeleteAcademySession() {
    fetch(`${API}/camcelAcademySession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'sessionID': cancelSessionID,
        'customerID': customerID,

      })
    }).then(response => response.json())
      .then(result => {
        console.log(result.status == 200)
        if (result.status == 200) {
          toast.success(result.Message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      setRerender(!rerender)

    handleAcademyClose()
  }


  function handleClassDeleteSession() {
    fetch(`${API}/camcelClassSession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'sessionID': cancelSessionID,
        'customerID': customerID,
      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == 200) {
          toast.success(result.Message, {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      setRerender(!rerender)

    handleClassClose()
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const data = [
    { title: 'One', body: 'one' },
    { title: 'Two', body: 'two' },
    { title: 'Three', body: 'three' }
  ];

if (loading || academyLoading || classLoading || clinicloading) return   <Box 
sx={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  height: '100vh' 
}}
>
<CircularProgress />
</Box>

  return (
    <div className="attendandce">
     <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick={false}
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>





      <Typography>
        <TableContainer sx={{ width: "70%", margin: "auto" }}>
        <Divider>GYM Attendance</Divider>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Date</TableCell>
                <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Branch</TableCell>
                <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Type</TableCell>

                <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gymAttendance?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.customerID}>
                  <TableCell sx={{ fontFamily: "poppins" }} align="center">{row.AttendanceDate}</TableCell>
                  <TableCell sx={{ fontFamily: "poppins" }} align="center">{row.Branch}</TableCell>
                  <TableCell sx={{ fontFamily: "poppins" }} align="center">{row.session_type}</TableCell>

                  <TableCell align="center">
                    {role === "Admin" && (
                      <Button
                        onClick={() => handleOnDelete(row.id)}
                        style={{ color: "red", border: "1px solid red" }}
                        variant="outlined"
                        startIcon={<DeleteIcon style={{ color: "red" }} />}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={gymAttendance.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Typography>

      {/* Gym Dialog */}
      <Dialog

        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ fontWeight: "bold", fontFamily: "poppins" }}> {"      Delete GYM session?       "}</DialogTitle>

        <DialogActions>
          <Button style={{ border: "2px solid red", color: "red", fontWeight: "bold" }} onClick={handleClose}>Close</Button>

          <Button style={{ border: "2px solid blue", backgroundColor: "#0065bd", color: "white", fontWeight: "bold" }} onClick={handleDeleteSession}>Confirm</Button>
        </DialogActions>
      </Dialog>


      {/* Academy Dialog */}

      <Dialog
        open={AcademyOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAcademyClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ fontWeight: "bold", fontFamily: "poppins" }}> {"     Delete Academy session?      "}</DialogTitle>

        <DialogActions>
          <Button style={{ border: "2px solid red", color: "red", fontWeight: "bold" }} onClick={handleClose}>Close</Button>

          <Button style={{ border: "2px solid blue", backgroundColor: "#0065bd", color: "white", fontWeight: "bold" }} onClick={handleDeleteAcademySession}>Confirm</Button>
        </DialogActions>
      </Dialog>


      {/* class Dialog */}
      <Dialog
        open={ClassOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClassClose}
        aria-describedby="alert-dialog-slide-description"
      >



        <DialogTitle style={{ fontWeight: "bold", fontFamily: "poppins" }}> {"      Delete Class session?       "}</DialogTitle>

        <DialogActions>
          <Button style={{ border: "2px solid red", color: "red", fontWeight: "bold" }} onClick={handleClose}>Close</Button>

          <Button style={{ border: "2px solid blue", backgroundColor: "#0065bd", color: "white", fontWeight: "bold" }} onClick={handleClassDeleteSession}>Confirm</Button>
        </DialogActions>
      </Dialog>


        {/* Clinic Dialog */}
        <Dialog
        open={ClinicOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClinicClose}
        aria-describedby="alert-dialog-slide-description"
      >



        <DialogTitle style={{ fontWeight: "bold", fontFamily: "poppins" }}> {"      Delete Clinic session?       "}</DialogTitle>

        <DialogActions>
          <Button style={{ border: "2px solid red", color: "red", fontWeight: "bold" }} onClick={handleClose}>Close</Button>

          <Button style={{ border: "2px solid blue", backgroundColor: "#0065bd", color: "white", fontWeight: "bold" }} onClick={handleDeleteClinicSession}>Confirm</Button>
        </DialogActions>
      </Dialog>



        <>
          <TableContainer style={{ width: "70%", margin: "80px auto" }} component={Paper}>


            <Divider>Academy Attendance</Divider>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Date</TableCell>
                  <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Branch</TableCell>
                  <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Type</TableCell>

                  <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center"></TableCell>



                </TableRow>
              </TableHead>
              <TableBody>
                {academyData?.slice(pageAcademy * rowsPerPageAcademy, pageAcademy * rowsPerPageAcademy + rowsPerPageAcademy).map((row) => (
                  <StyledTableRow key={row.customerID}>
                    <StyledTableCell  sx={{ fontFamily: "poppins" }} align="center">{row.Date}</StyledTableCell>
                    <StyledTableCell  sx={{ fontFamily: "poppins" }} align="center">{row.Branch}</StyledTableCell>
                    <TableCell  sx={{ fontFamily: "poppins" }} align="center">{row.session_type}</TableCell>


                    <StyledTableCell align="center">
                      {role === "Admin" ? (<Button onClick={() => handleOnAcademyDelete(row.id)} style={{ color: "red", border: "1px solid red" }} variant="outlined" startIcon={<DeleteIcon style={{ color: "red" }} />}>
                        Delete
                      </Button>) : ("")}
                    </StyledTableCell>


                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={academyData.length}
            rowsPerPage={rowsPerPageAcademy}
            page={pageAcademy}
            onPageChange={handleChangePageAcademy}
            onRowsPerPageChange={handleChangeRowsPerPageAcademy}
          />
        </>

    


        <>
        <TableContainer style={{ width: "70%", margin: "80px auto" }} component={Paper}>


        <Divider>Class Attendance</Divider>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
            <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Date</TableCell>
            <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Branch</TableCell>
            <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Type</TableCell>

            <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center"></TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
          {classData?.slice(pageClass * rowsPerPageClass, pageClass * rowsPerPageClass + rowsPerPageClass).map((row) => (
              <StyledTableRow key={row.customerID}>
                <StyledTableCell  sx={{ fontFamily: "poppins" }} align="center">{row.AttendanceDate}</StyledTableCell>
                <StyledTableCell  sx={{ fontFamily: "poppins" }} align="center">{row.Branch}</StyledTableCell>
                <StyledTableCell  sx={{ fontFamily: "poppins" }} align="center">{row.session_type}</StyledTableCell>


                <StyledTableCell align="center">
                  {role === "Admin" ? (<Button onClick={() => handleOnClassDelete(row.id)} style={{ color: "red", border: "1px solid red" }} variant="outlined" startIcon={<DeleteIcon style={{ color: "red" }} />}>
                    Delete
                  </Button>) : ("")}
                </StyledTableCell>


              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={classData.length}
        rowsPerPage={rowsPerPageClass}
        page={pageAcademy}
        onPageChange={handleChangePageClass}
        onRowsPerPageChange={handleChangeRowsPerPageClass}
      />

      </>


<>
<TableContainer style={{ width: "70%", margin: "80px auto" }} component={Paper}>


<Divider>Clinic Attendance</Divider>
<Table sx={{ minWidth: 700 }} aria-label="customized table">
  <TableHead>
    <TableRow>
    <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Date</TableCell>
    <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center">Branch</TableCell>

    <TableCell style={{ width: "50%", fontWeight: "bold", backgroundColor: "#ececec", fontFamily: "poppins" }} align="center"></TableCell>


    </TableRow>
  </TableHead>
  <TableBody>
  {clinicData?.slice(pageClass * rowsPerPageClass, pageClass * rowsPerPageClass + rowsPerPageClass).map((row) => (
      <StyledTableRow key={row.customerID}>
        <StyledTableCell  sx={{ fontFamily: "poppins" }} align="center">{row.date}</StyledTableCell>
        <StyledTableCell  sx={{ fontFamily: "poppins" }} align="center">{row.branch}</StyledTableCell>


        <StyledTableCell align="center">
          {role === "Admin" ? (<Button onClick={() => handleOnClientDelete(row.id, row.packID)} style={{ color: "red", border: "1px solid red" }} variant="outlined" startIcon={<DeleteIcon style={{ color: "red" }} />}>
            Delete
          </Button>) : ("")}
        </StyledTableCell>


      </StyledTableRow>
    ))}
  </TableBody>
</Table>
</TableContainer>
<TablePagination
rowsPerPageOptions={[5, 10, 25]}
component="div"
count={clinicData.length}
rowsPerPage={rowsPerPageClinic}
page={pageClinic}
onPageChange={handleChangePageClinic}
onRowsPerPageChange={handleChangeRowsPerPageClinic}
/>

</>


    </div>
  )
}
export default CustomerAttanedancePage

