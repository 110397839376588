import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';
import { Table } from 'antd';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { HiOutlineDocumentReport } from "react-icons/hi";

import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Slider from '@mui/material/Slider';

import * as XLSX from 'xlsx';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';



const API = process.env.REACT_APP_API

function ClinicFinance() {
	const [filtered, setFiltered] = useState("All")

	const [selectedBranch, setSelectedBranch] = useState("All")
	const [branchesLoading, setBranchesLoading] = useState()

	const [branches, setBranches] = useState()
	const [loading, setLoading] = useState()
	const [financeDage, setFinanceData] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
	const [rednder, setRerender] = useState()
	const [trainer, setTrainer] = useState([])
	const [trainersLoading, setTrainersLoading] = useState()
	const [packagesLoading, setPackagesLoading] = useState(true)
	const [gender, setSelectedGender] = useState("All")
	const [Packages, setPackages] = useState([])
	const [trainerName, settrainerName] = useState("All")

	const [customerID, setCustomerID] = useState("All")
	const [returnTotal, setReturnTotal] = useState()
	const [returndata, setReturnData] = useState()


	const [selectedPackage, setSelectedPackage] = useState("All")
	const [ageFrom, setAgeFrom] = useState("All")
	const [ageTo, setAgeTo] = useState("All")
	const [searched, setSearched] = useState(false)

	const [debtdata, setDebtData] = useState()
	const [debtTotal, setDebtTotal] = useState()


	const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);


	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});




	const [debtTableParams, setDebtTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});

	const [returnTableParams, setReturnTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
		},
	});

	const fetchData = () => {
		setLoading(true);
		fetch(`${API}/getAllClinicFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}`, {
			method: 'GET',
			credentials: 'include'
		}).then((res) => res.json())

			.then((data) => {
				setFinanceData(data.result)
				setLoading(false);
				setTableParams({
					...tableParams,
					pagination: {
						...tableParams.pagination,
						total: data.result?.length || 0,
					},
				});
			});
	};
	useEffect(fetchData, []);


	const fetchDebtData = () => {
		setLoading(true);
		fetch(`${API}/getClinicDebt?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`).then((res) => res.json())
			.then((results) => {
				setDebtData(results.clinicPayDebt);
				setDebtTotal(results.total[0].total)
				setLoading(false);
				setDebtTableParams({
					...debtTableParams,
					pagination: {
						...debtTableParams.pagination,
						total: results.result?.length || 0,

					},
				});
			});
	};
	useEffect(fetchDebtData, [searched]);



	const fetchReturnData = () => {
		setLoading(true);
		fetch(`${API}/clinicReturnFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}&customerID=${customerID}`).then((res) => res.json())
			.then((results) => {
				setReturnData(results.clinicReturn);
				setReturnTotal(results.total[0].total)

				setLoading(false);
				setReturnTableParams({
					...returnTableParams,
					pagination: {
						...returnTableParams.pagination,
						total: results.result?.length || 0,

					},
				});
			});
	};
	useEffect(fetchReturnData, [searched]);


	const handleTableChange = (pagination, filters, sorter) => {
		const updatedParams = {
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		};

		setTableParams(updatedParams);

	};

	const handleDebtTableChange = (pagination, filters, sorter) => {
		const updatedParams = {
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		};
		setDebtTableParams({
			updatedParams

		});
	};
	const handleReturnTableChange = (pagination, filters, sorter) => {
		const updatedParams = {
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		};

		setReturnTableParams(updatedParams);

	};
	const columns = [
		{
			title: 'Customer ID',
			dataIndex: 'CustomerID',
			sorter: (a, b) => a.CustomerID - b.CustomerID,
		},
		{
			title: 'Full name',
			dataIndex: 'Fullname',
			align: "center",
			sorter: {
				compare: (a, b) => sorter(a.Fullname, b.Fullname),
			},



		},

		{
			title: 'Phone',
			dataIndex: 'Phone',
			align: "center"


		},

		{
			title: 'Age',
			dataIndex: 'customerAge',
			sorter: (a, b) => a.customerAge - b.customerAge,
			align: "center"



		},


		{
			title: 'National ID',
			dataIndex: 'NationalID',
			align: "center"


		},
		//   PayDate	Category	Dep	Trainer	Branch
		{
			title: 'Paid Value',
			dataIndex: 'PaidValue',
			sorter: (a, b) => a.PaidValue - b.PaidValue,
			align: "center"


		},
		{
			title: 'Remianing Value',
			dataIndex: 'Remaining',
			sorter: (a, b) => a.Remaining - b.Remaining,
			align: "center"

		},
		{
			title: 'Paid Debt',
			dataIndex: 'TotalPaydebtPaidValue',
			sorter: (a, b) => a.TotalPaydebtPaidValue - b.TotalPaydebtPaidValue,
			align: "center"

		},
		{
			title: 'Actual Remains',
			render: (_, record) => Number(record.Remaining) - Number(record.TotalPaydebtPaidValue),
			align: "center",
			sorter: (a, b) => {
				const valueA = Number(a.Remaining) - Number(a.TotalPaydebtPaidValue);
				const valueB = Number(b.Remaining) - Number(b.TotalPaydebtPaidValue);
				return valueA - valueB; // Numeric comparison
			},


		},
		{
			title: 'Discount',
			dataIndex: 'Discount',
			sorter: (a, b) => a.discount - b.discount,
			align: "center"

		},
		{
			title: 'Pay Date',
			dataIndex: 'PayDate',
			sorter: (a, b) => new Date(a.PayDate) - new Date(b.PayDate),
			align: "center"


		},

		{
			title: 'Category',
			dataIndex: 'Category',
			align: "center"

		},




		{
			title: 'Branch',
			dataIndex: 'Branch',
			align: "center",
			sorter: {
				compare: (a, b) => sorter(a.Branch, b.Branch),
			},

		},


	];



	const Debtcolumns = [
		{
			title: 'Customer ID',
			dataIndex: 'CustomerID',
			align: "center",

			sorter: (a, b) => a.CustomerID - b.CustomerID,
		},
		{
			title: 'Full name',
			dataIndex: 'Fullname',
			align: "center",
			sorter: {
				compare: (a, b) => sorter(a.Fullname, b.Fullname),
			},




		},

		{
			title: 'Phone',
			dataIndex: 'Phone',
			align: "center",
			sorter: {
				compare: (a, b) => sorter(a.Phone, b.Phone),
			},


		},

		{
			title: 'Age',
			dataIndex: 'customerAge',
			sorter: (a, b) => a.customerAge - b.customerAge,
			align: "center"



		},


		{
			title: 'National ID',
			dataIndex: 'NationalID',
			align: "center"


		},

		{
			title: 'Paid Value',
			dataIndex: 'PaidValue',
			sorter: (a, b) => a.PaidValue - b.PaidValue,
			align: "center"


		},


		{
			title: 'Pay Date',
			dataIndex: 'Date',
			sorter: (a, b) => new Date(a.Date) - new Date(b.Date),
			align: "center"


		},



		{
			title: 'Category',
			dataIndex: 'Category',
			align: "center"

		},




		{
			title: 'Branch',
			dataIndex: 'Branch',
			align: "center",
			sorter: {
				compare: (a, b) => sorter(a.Branch, b.Branch),
			},

		},

	];

	function onChange(date, dateString) {
		setDate(dateString)
		setFiltered("filtered")

	}




	function onEndDatehange(date, dateString) {
		setEnDate(dateString)
		setFiltered("filtered")


	}



	useEffect(function () {
		async function fetchBranches() {
			try {
				setBranchesLoading(true)
				const res = await fetch(`${API}/branches`)
				const data = await res.json()
				setBranches(data.branches)

			} catch (e) {
				console.log(e)
			} finally {
				setBranchesLoading(false)
			}
		} fetchBranches()
	}, [])

	useEffect(function () {
		async function fetchDate() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getAllClinicFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}`)
				const data = await res.json()
				console.log(data.data)
				setFinanceData(data.data)

			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchDate()
	}, [searched])


	useEffect(function () {
		async function getTrainers() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				setTrainer(data.result)
			} catch (e) {
				console.log(e)
			} finally {
				setTrainersLoading(false)
			}
		} getTrainers()

	}, [])







	async function handleFilter() {
		try {
			setLoading(true)
			const res = await fetch(`${API}/getAllClinicFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}`, {
				method: 'GET',
				credentials: 'include'
			})
			const data = await res.json()
			setFinanceData(data.data)
			setSearched(true)

		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)


		}





	}



	const sumPaid = financeDage?.map(item => item.PaidValue).reduce((a, b) => a + b, 0)
	const sumRemaining = financeDage?.map(item => item.Remaining).reduce((a, b) => a + b, 0)
	const paidDebt = financeDage?.map(item => Number(item.TotalPaydebtPaidValue)).reduce((a, b) => a + b, 0)
	const discount = financeDage?.map(item => Number(item.Discount)).reduce((a, b) => a + b, 0)

	const handleExport = () => {
		// Create a worksheet from the dataSource
		const ws = XLSX.utils.json_to_sheet(financeDage);

		// Create a workbook
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Add worksheet to workbook

		// Generate the Excel file and prompt download
		XLSX.writeFile(wb, 'TableData.xlsx');
	};


	{ if (branchesLoading || trainersLoading) return <CircularProgress sx={{ display: "flex", width: "fit-content", top: "50%", left: "50%", position: "absolute" }} /> }
	return (


		<div style={{ backgroundColor: "#eaedf4", height: "100%", width: "100%" }} className="finance-page-container">


			<h3 style={{ margin: "0 auto", width: "300px" }}>Clinic Finance</h3>
			<Container
				sx={{
					padding: '20px',
					borderRadius: '8px',
					backgroundColor: "white",
					boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
					marginBottom: "20px",

				}}
			>

				<div style={{ borderRadius: "8px", width: "40%", margin: "20px auto", display: "flex", justifyContent: "space-between" }} className="datepicker-container">
					<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='From' format={"YYYY-MM-DD"} onChange={onChange} />
					<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", }} placeholder='To' format={"YYYY-MM-DD"} onChange={onEndDatehange} />

				</div>

				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: '20px', marginTop: "40px" }}>
					<div style={{ display: "flex", margin: "0 auto", width: "auto", flexDirection: "row", alignItems: "flex-start", textAlign: "center", height: "80px" }}>
						{['Branch', 'Gender'].map((placeholder, index) => (
							<div key={index} style={{ position: "relative", marginLeft: index > 0 ? "5px" : "0" }}>
								<label style={{
									position: "absolute",
									top: "-20px", // Position the label higher
									fontSize: "12px",
									color: "#777",
									padding: "0 5px",
									zIndex: 1,
								}}>
									{placeholder}
								</label>
								<select
								onChange = {
									index === 0 ? (e) => setSelectedBranch(e.target.value) : 
									index === 1 ? (e) => setSelectedGender(e.target.value) : null
								}
								
									style={{
										width: index === 1 ? "170px" : "150px",
										height: "40px",
										border: "none",
										textAlign: "center",
										alignItems: "center",
										marginTop: "10px", // Add space below the label
										backgroundColor: "#eaedf4",
										borderRadius: "8px",
									}}
									defaultValue=""
								>
									<option value="All" >All</option>
									
									{index === 0 && (
										<>
											{branches?.map(branch => (
												<option key={branch.name} value={branch.name}>{branch.name}</option>
											))}
										</>
									)}
									{index === 1 && (
										<>
											<option value="Male">Male</option>
											<option value="Female">Female</option>
										</>
									)}
									{/* {index === 3 &&  (
											<>
												<option value="Package1">Package 1</option>
												<option value="Package2">Package 2</option>
											</>
										)} */}

									{index === 3 &&
										<>
											{/* {Packages.map(pack=>{
										return (
											<option key={pack.name} value={pack.packId}>{branch.name}</option>

										)

									})} */}
										</>
										// <><option key={"1"} value={"1"}>{"1"}</option><option key={"1"} value={"8"}>{"8"}</option><option key={"1"} value={"12"}>{"12"}</option></>
									}
								</select>
							</div>
						))}
					</div>
					<div style={{
						position: "relative", marginLeft: "5",
					}}>

						<div style={{ display: "flex", flexDirection: "row", marginBottom: "15px", }}>
							<div style={{ marginRight: "10px" }}>	<label style={{
								position: "absolute",
								top: "-20px", // Position the label higher
								fontSize: "12px",
								color: "#777",
								zIndex: 1,
								marginBottom: "5px",
								width: "100px",
							}}>
								Age from
							</label>

								<select onChange={(e) => setAgeFrom(e.target.value)} style={{
									width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
								}}>

									{Array.from({ length: 80 }, (_, index) => (
										<option value={index}>
											{index}
										</option>

									))}
								</select></div>
							<div>	<label style={{
								position: "absolute",
								top: "-20px", // Position the label higher
								fontSize: "12px",
								color: "#777",
								zIndex: 1,
								marginBottom: "5px",
								width: "100px",


							}}>
								Age to
							</label>

								<select onChange={(e) => setAgeTo(e.target.value)} style={{
									width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
								}}>

									{Array.from({ length: 80 }, (_, index) => (
										<option value={index}>
											{index}
										</option>

									))}
								</select></div>
						</div>
					</div>

					<Button
						variant="contained"
						onClick={() => setSearched(!searched)}
						startIcon={<SearchIcon style={{ color: "black" }} />}
						sx={{
							":hover": {
								backgroundColor: '#eaedf4',

								boxShadow: 'none',

							},
							backgroundColor: "#d7d9e9",

							color: 'black',
							borderRadius: '6px',
							fontWeight: "bold",
							marginTop: "10px",
							margin: "auto",
							width: "150px",
							fontWeight: "Bold",
							boxShadow: 'none',
						}}
					>
						Search
					</Button>

					{searched ? (
						<lebel style={{ marginTop: "10px" }}>
							<strong>{`${gender}`}</strong>
							{` Customers  `}
							<strong>{` between ${ageFrom} years and ${ageTo} years`}</strong>
							{` in `}
							<strong>{`${selectedBranch} branch`}</strong>
							{` within `}
							<strong>{`${startDate} and ${endDate}`}</strong>
							{` with  `}
							<strong>{`${trainerName} `}</strong>
						</lebel>
					) : (
						""
					)}
				</div>

			</Container>









			{loading ? (<Box sx={{ display: 'flex', margin: "0 auto", width: "10%" }}>
				<CircularProgress />
			</Box>) : (<>
				<h4 style={{ margin: "auto", width: "fit-content" }}>Subscriptions Finance</h4>
				<Container

					sx={{
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "flex",
						backgroundColor: "white"

					}}
				>

					<Paper
						elevation={0}

						sx={{
							padding: '10px',
							// background: 'linear-gradient(to right, #025d82, #0094d4)',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",

							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							color: "black",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",
						}}
					>


						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<AccountCircleIcon sx={{ fontSize: "30px" }} />

							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "14px" }}>
								{financeDage?.length}
							</Typography>
						</Box>
					</Paper>

					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							color: "black",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>


						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon sx={{ fontSize: "30px" }} />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "14px" }}>
									Total
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumPaid) + Number(sumRemaining)} EGP
							</Typography>
						</Box>
					</Paper>

					<Paper
						elevation={10}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",


							boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
						}}
					>
						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon sx={{ fontSize: "30px" }} />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "14px", fontSize: "14px" }}>
									Actual Paid
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{sumPaid} EGP
							</Typography>
						</Box>
					</Paper>

					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",

							boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
						}}
					>
						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon sx={{ fontSize: "30px" }} />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "14px" }}>
									Total Remaining
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{sumRemaining} EGP
							</Typography>
						</Box>

					</Paper>

					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "14px" }}>
									Remaining Debt
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumRemaining) - Number(paidDebt)} EGP
							</Typography>
						</Box>





					</Paper>
					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "14px" }}>
									Discount
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(discount)} EGP
							</Typography>
						</Box>





					</Paper>
				</Container>



				<Container sx={{
					width: "fit-content", margin: "auto", marginBottom: "10px"
				}}>
					<Button
						variant="contained"
						onClick={handleExport}
						startIcon={<InsertDriveFileIcon style={{ color: "black" }} />}
						sx={{
							":hover": {
								backgroundColor: '#eaedf4',

								boxShadow: 'none',

							},
							backgroundColor: "#d7d9e9",

							color: 'black',
							borderRadius: '6px',
							fontWeight: "bold",
							marginTop: "10px",
							margin: "auto",
							width: "200px",
							fontWeight: "Bold",
							boxShadow: 'none',
						}}
					>
						Download Excel
					</Button>
				</Container>

				<Table
					columns={columns}
					//   rowKey={(record) => record.login.uuid}
					dataSource={financeDage}
					pagination={{
						...tableParams.pagination,
						showSizeChanger: true, // Optional for allowing size changes
						pageSizeOptions: ["10", "20", "50", "100"], // Optional
					}}				
						loading={loading}
					onChange={handleTableChange}
				/>



<Container
					sx={{
						padding: '25px',
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "block",
						flexDirection: "column",
						backgroundColor: "white",
						marginTop:"10px"

					}}
				>



					<h4 style={{ margin: "auto", width: "fit-content" }}>Debt Finance</h4>

					<Paper
						elevation={0}
						sx={{
							margin: "auto",
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Total Paid
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(debtTotal)} EGP
							</Typography>
						</Box>
					</Paper>
				</Container>


				<Table
					style={{ width: "70%", margin: "auto" }}

					columns={Debtcolumns}
					//   rowKey={(record) => record.login.uuid}
					dataSource={debtdata}
					pagination={{
						...debtTableParams.pagination,
						showSizeChanger: true, // Optional for allowing size changes
						pageSizeOptions: ["10", "20", "50"], // Optional
					}} loading={loading}
					onChange={handleDebtTableChange}
				/>


				<Container
					sx={{
						padding: '25px',
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "block",
						flexDirection: "column",
						backgroundColor: "white"

					}}
				>
					<h4 style={{ margin: "auto", width: "fit-content" }}>Return Finance</h4>

					<Paper
						elevation={0}
						sx={{
							margin: "auto",
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",

						}}
					>

						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Total Paid
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(returnTotal)} EGP
							</Typography>
						</Box>
					</Paper>
				</Container>


				<Table
					style={{ width: "70%", margin: "auto" }}
					columns={Debtcolumns}
					//   rowKey={(record) => record.login.uuid}
					dataSource={returndata}
					pagination={{
						...returnTableParams.pagination,
						showSizeChanger: true, // Optional for allowing size changes
						pageSizeOptions: ["10", "20", "50", "100"], // Optional
					}} loading={loading}
					onChange={handleReturnTableChange}
				/>


				<Container
					sx={{
						padding: '25px',
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "block",
						backgroundColor: "white"

					}}
				>
					<Box margin="auto" width="fit-content" display="flex" alignItems="center" textAlign="center">
						<HiOutlineDocumentReport size={40} />	<h4>Final Report</h4>
					</Box>

					<Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>



						<Box width="250px" border="1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px" height="90px" borderRadius="12px" >
							<Box sx={{ padding: "10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Subscriptions
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumPaid)} EGP
							</Typography>
						</Box>


						<Box width="250px" border="1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px" height="90px" borderRadius="12px" >
							<Box sx={{ padding: "10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Paid Debt
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(debtTotal)} EGP
							</Typography>
						</Box>


						<Box width="250px" border="1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px" height="90px" borderRadius="12px" >
							<Box sx={{ padding: "10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Return
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(returnTotal)} EGP
							</Typography>
						</Box>


						<Box width="250px" border="1px solid #e4e4e4" backgroundColor="#edf0f7" display="block" margin="10px" height="90px" borderRadius="12px" >
							<Box sx={{ padding: "10px", display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									Net Profit
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumPaid) + Number(debtTotal) - Number(returnTotal)} EGP
							</Typography>
						</Box>

					</Box>
					<Container></Container>

				</Container>

			</>


			)




			}





		</div>
	)

}

export default ClinicFinance