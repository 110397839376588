import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react'
import AddNewUser from './pages/AddNewUser';
import LandingPage from './pages/landingpage';
import AddCustomerPage from './pages/addCustomer';
import CustomersPage from './pages/customersPage';
import Login from './pages/login';
import { BrowserRouter, Route, Routes, Router } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from "./components/privateRoute";


import { Outlet, Navigate } from 'react-router-dom';

import UserPage from './pages/userPage';
import PackagePage from './pages/packagePage';
import AddAcademy from './pages/addAcademy';
import Academies from './pages/academies';
import CustomerProfile from './pages/customerProfile';
import AllUsersPage from './pages/allUsersPage';
import AcademiesCustomers from './pages/academiesCustomer';
import AddAcademyPackage from './pages/addGymPackage';
import AddClassPage from './pages/gymClass/addClass';
import AddClassFamilyPage from './pages/gymClass/addClassFamily';
import ClassPackagePage from './pages/gymClass/classPackage';
import AllClassesPage from './pages/gymClass/AllClasses&subscribe';
import CustomersInClass from './pages/gymClass/customersInClasses';
import ClassesPage from './pages/gymClass/classesPage';
import OneTimeSessionPage from './pages/onrTimeSession';
import OTSTable from './components/addSessionTable';
import AddOTS from './components/addOTS';
import AllCustomersPage from './pages/allCustomersPage';
import CustomerAttanedancePage from './pages/customerAttendancePage';
import EditGymPackagesPage from './pages/editGymPackagees';
import FinancePage from './pages/finance';
import MiniDrawer from './pages/drawer';
import AcademyFinance from './components/financeComponenets/academyFinance';
import ClassFinance from './components/financeComponenets/classFinance';
import GymFinance from './components/financeComponenets/gymFinance';
import RentFinance from './components/financeComponenets/rentFinance';
import NewClasses from './pages/gymClass/classes';
import EditAcademyPackages from './pages/editAcademiesPackage';
import AddOTSPackage from './components/otspackage';
import DietPage from './components/dietComponents/dietPage';
import AddDiet from './components/dietComponents/addDietCustomer';
import AddDietPack from './components/dietComponents/addDietPackage';
import AllDietCustomer from './components/dietComponents/allDietCustomers';
import AddBranch from './pages/addBranch';
import OTSfinance from './components/financeComponenets/otsFinance';
import DietFinance from './components/financeComponenets/dietFinance';
import Rent from './components/rentComponents/rent';
import RentPage from './pages/rentPage';
import AddTenant from './components/rentComponents/addTentnat';
import Tenants from './components/rentComponents/tenants';
import RentCategory from './components/rentComponents/categoryStatus';
import AllFinanceByTime from './components/financeComponenets/allFinanceByTime';
import ClassTrainerFinance from './components/financeComponenets/classTrainerFinance';
import HealthProfile from './pages/customerHealtProfile';
import ResetPasswordPage from './pages/resetPassword';
import ClassPackages from './pages/gymClass/packages';
import InvitationsPage from './pages/invitationsPage';
import TrainerContract from './pages/trainerContrace';
import TrainersDataBase from './pages/trainersDatabase';
import TrainerProfile from './components/trainers/trainerProfile';
import AttendanceMain from './pages/attendanceByTime';
import GymAttendance from './components/attendance/gym';
import AcademyAttendance from './components/attendance/academy';
import ClinicAttendance from './components/attendance/clinicAttendance';
import AllAttendance from './components/attendance/allAttendance';

import ClassAttendance from './components/attendance/class';
import FinanceMainPage from './pages/financePage';
import { AuthProvider, useAuth } from './contexts/authContext';

import TrainersDashboad from './pages/trainersDashboard';
import RedirectPage from './pages/QRRedirectPage';
import CustomerQRInfo from './pages/QRPageDetails';
import { useIdleTimer } from 'react-idle-timer/legacy'
import SMSSystem from './pages/SMSSystem';
import { useState } from 'react'
import TimeOutModal from './components/autoLogoutModal/logOutTimeModal'
import { useLocation } from 'react-router-dom';
// import Clinic from './pages/Clinic/clinic';
import AddClinicPackage from './pages/Clinic/addPackage';
import EditClinicPackage from './pages/Clinic/editPackage';
import Clinic from './pages/clinic';
import ClinicFinance from './components/financeComponenets/ClinicFinance';
import { useNavigate } from 'react-router-dom';
import AuthCheck from './contexts/authCheck';
import CustomerHistory from './components/customerProfile/customerHistory';










const AppRoutes = () => {
  const { token } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logout, timeoutDuration } = useAuth();


  const [countdown, setCountdown] = useState(60); // 1-minute countdown
  const [isCountingDown, setIsCountingDown] = useState(false);
  const idleChannel = new BroadcastChannel('idle_channel');


  const RoleAccess = ({ roles = [] }) => {
    const user = localStorage.getItem("Roles");
    return !roles.length || roles.includes(user)
      ? <Outlet />
      : <Navigate to="/" replace />;
  };

  // check token expiry











  // Logout countdown modal

  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);

  // const handleClose = () => {
  //   setOpen(false); // Close the modal
  //   setCountdown(60)    
  //   idleChannel.postMessage({ type: 'close' });
  // };


  // const handleOnIdle = () => {
  //   if (isAuthenticated) {
  //     setOpen(true);
  //     setIsCountingDown(true);
  //     idleChannel.postMessage('idle'); // Notify other tabs
  //   }
  // };

  // const handleLogout = () => {
  //   // Your logout logic here
  //   setOpen(false);
  //   logout();
  //   idleChannel.postMessage({ type: 'close' }); // Notify other tabs to close modal
  // };

  // const { reset } = useIdleTimer({
  //   timeout: 540000, // 9 minutes in milliseconds
  //   onIdle: handleOnIdle,
  //   debounce: 500,
  // });

  // useEffect(() => {
  //   let countdownInterval;

  //   if (open) {
  //     countdownInterval = setInterval(() => {
  //       setCountdown((prev) => {
  //         if (prev === 1) {
  //           handleLogout();
  //           clearInterval(countdownInterval);
  //           return 0;
  //         }
  //         return prev - 1;
  //       });
  //     }, 1000);
  //   }

  //   return () => clearInterval(countdownInterval);
  // }, [open]);

  // useEffect(() => {
  //   if (token) {
  //     setIsAuthenticated(true);
  //   } else {
  //     setIsAuthenticated(false);
  //   }
  // }, [token]);

  // const handleMouseMove = () => {
  //   // Reset the idle timer only if the modal is not open
  //   if (!open) {
  //     setCountdown(60);
  //     reset(); // Reset the idle timer
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('mousemove', handleMouseMove);

  //   return () => {
  //     window.removeEventListener('mousemove', handleMouseMove); // Clean up event listener on unmount
  //   };
  // }, [open]);

  // useEffect(() => {
  //   const handleMessage = (message) => {
  //     if (message.data === 'idle') {
  //       setOpen(true);
  //       setIsCountingDown(true);
  //     } else if (message.data.type === 'close') {
  //       setOpen(false);
  //     }
  //   };

  //   idleChannel.addEventListener('message', handleMessage);

  //   return () => {
  //     idleChannel.removeEventListener('message', handleMessage);
  //   };
  // }, []);


  return (

    <>
      <Routes>

        <Route element={<PublicRoute />}>
          <Route path='login' element={<Login />} />
        </Route>



        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route index element={<LandingPage />} />
        <Route path="access/:id" element={<RedirectPage />} />
        <Route path="customer-info/:id" element={<CustomerQRInfo />} />

        {/* Private Routes */}

        {/* clinic routes  */}

        <Route path="clinic" element={<Clinic />}></Route>

        <Route element={<PrivateRoute requiredRoles={["Admin"]} />}>
          <Route path="clinic/add-package" element={<AddClinicPackage />}></Route>
          <Route path="clinic/packages" element={<EditClinicPackage />}></Route>
          <Route path="add-package" element={<PackagePage />} />

        </Route>


        <Route element={<PrivateRoute  />}>
        <Route path="user" element={<UserPage />} />

</Route>

        <Route path="trainers-dashboard" element={<TrainersDashboad />} />
        <Route path="sms" element={<SMSSystem />} />

        <Route element={<PrivateRoute requiredRoles={["Admin", "General Manager", "Receptionist"]} />}>
          <Route path="classes" element={<NewClasses />}>
            <Route path="add-class-family" element={<AddClassFamilyPage />} />
            <Route path="class-package" element={<ClassPackagePage />} />
            <Route path="customers" element={<AllClassesPage />} />
            <Route path="all-classes" element={<ClassesPage />} />
            <Route path="add-class" element={<AddClassPage />} />
            <Route path='edit-class-packages' element={<ClassPackages />} />

          </Route>
        </Route>




        <Route path='financial' element={<FinanceMainPage />} />


        <Route element={<PrivateRoute requiredRoles={["Admin", "General Manager"]} />}>

        <Route path="invitations" element={<InvitationsPage />} />

          <Route path='financial' element={<MiniDrawer />}>
            <Route path='academy' element={<AcademyFinance />} />
            <Route path='gym' element={<GymFinance />} />
            <Route path='rent' element={<RentFinance />} />

            <Route path='all' element={<FinancePage />} />
            <Route path='class' element={<ClassFinance />} />
            <Route path='all-finance-time' element={<AllFinanceByTime />} />
            <Route path='ots' element={<OTSfinance />} />
            <Route path='diet' element={<DietFinance />} />
            <Route path="clinic" element={<ClinicFinance />}></Route>
            <Route path='class-trainer-finance' element={<ClassTrainerFinance />} />
          </Route>
        </Route>

        <Route element={<PrivateRoute requiredRoles={["Admin"]} />}>
          <Route path="add-branch" element={<AddBranch />} />
          <Route path="edit-academy-packages" element={<EditAcademyPackages />} />


        </Route>


        <Route element={<PrivateRoute requiredRoles={["Admin", "General Manager", "Receptionist"]} />}>
          <Route path='rent' element={<RentPage />}>
            <Route path="add-category" element={<Rent />} />
            <Route path="add-tentant" element={<AddTenant />} />
            <Route path="tenants" element={<Tenants />} />
            <Route path="categories" element={<RentCategory />} />
          </Route>
        </Route>





        <Route element={<PrivateRoute requiredRoles={["Admin", "General Manager", "Receptionist"]} />}>
          <Route path="add-customer" element={<AddCustomerPage />} />

        </Route>

        <Route path="trainer-contract" element={<TrainerContract />} />
        <Route path="trainers" element={<TrainersDataBase />} />
        <Route path="trainer/:trainerid" element={<TrainerProfile />} />
        <Route element={<PrivateRoute requiredRoles={["Admin"]} />}>
          <Route path='add-user' element={<AddNewUser />} />
        </Route>

        <Route element={<PrivateRoute requiredRoles={["Admin"]} />}>
          <Route path="add-academy" element={<AddAcademy />} />

        </Route>


        <Route element={<PrivateRoute requiredRoles={["Admin", "General Manager", "Receptionist", "Trainer"]} />}>
          <Route path="academy" element={<Academies />} />
          <Route path="academies/:id" element={<AcademiesCustomers />} />
          <Route path="profile/:id/" element={<CustomerProfile />} />
          <Route path="health/:customerID" element={<HealthProfile />} />
          <Route path="customers" element={<CustomersPage />} />


        </Route>



        <Route element={<PrivateRoute requiredRoles={["Admin", "General Manager", "Receptionist"]} />}>
          <Route path="history/:customerID" element={<CustomerHistory />} />

        </Route>



        <Route element={<PrivateRoute requiredRoles={["Admin","General Manager" ]} />}>
          <Route path="users" element={<AllUsersPage />} />

        </Route>

        <Route element={<PrivateRoute requiredRoles={["Admin"]} />}>
          <Route path="diet" element={<DietPage />}>
            <Route path="add" element={<AddDiet />} />
            <Route path="all" element={<AllDietCustomer />} />
          </Route>

          <Route path="add-diet-package" element={<AddDietPack />} />

          <Route path="add-gym-package" element={<AddAcademyPackage />} />
          <Route path="edit-gym-package" element={<EditGymPackagesPage />} />


        </Route>


        <Route element={<PrivateRoute requiredRoles={["Admin", "General Manager", "Receptionist"]} />}>
          <Route path="all-customers" element={<AllCustomersPage />} />
          <Route path="customer-attendance/:customerID" element={<CustomerAttanedancePage />} />
          <Route path='attendance' element={<AttendanceMain />} >
            <Route path="all" index element={<AllAttendance />} />
            <Route path="gym" element={<GymAttendance />} />
            <Route path="Clinic" element={<ClinicAttendance />} />
            <Route path="class" element={<ClassAttendance />} />
            <Route path="academy" element={<AcademyAttendance />} />
          </Route>
          <Route path="classes/:id" element={<CustomersInClass />} />

        </Route>


        {/* <Route path="ots" element={<OneTimeSessionPage />} >
          <Route index path="all" element={<OTSTable />} />
          <Route path="add" element={<AddOTS />} />
          <Route path="add-package" element={<AddOTSPackage />} />
        </Route> */}
        {/* End of private routes */}
      </Routes>

      {/* <TimeOutModal countdown={countdown}setOpen={setOpen} open={open} handleOpen={handleOpen} reset={reset} handleClose={handleClose} /> */}

    </>
  )

};

const App = () => {
  return (
    <BrowserRouter>
      {/* <AuthCheck  /> */}
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
};


export default App;