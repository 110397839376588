import { Container, Typography, Paper, Box, CircularProgress, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Table } from 'antd';
import Navbar from "../navbar";
 
function CustomerHistory() {
	const API = process.env.REACT_APP_API
	const [loading, setLoading] = useState(true)

	// GYM
	const [gym, setGym] = useState()
	const [gymPaid, setGymPaid] = useState()
	const [gymRemaining, setGymRemaining] = useState()
	const [gymReturn, setGymReturn] = useState()


	// CLASS

	const [classData, setClass] = useState()
	const [classPaid, setClassPaid] = useState()
	const [classRemaining, setClassRemaining] = useState()
	const [classReturn, setClassReturn] = useState()

	// Academy

	const [academy, setAcademy] = useState()
	const [academyPaid, setAcademyPaid] = useState()
	const [academyRemaining, setAcademyRemaining] = useState()
	const [academyReturn, setAcademyReturn] = useState()


	// diet
	const [diet, setDiet] = useState()
	const [dietPaid, setDietPaid] = useState()


	// Clinic
	const [clinicPaid, setClinicPaid] = useState()
	const [clinicRemaining, setClinicRemaining] = useState()
	const [clinicReturn, setClinicReturn] = useState()



	const [clinic, setClinic] = useState()
	const params = useParams()
	const { customerID } = params

	const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);


	const GYMcolumns = [


		{
			title: 'Customer ID',
			dataIndex: 'customerID',
			align: "center"
		},
		{
			title: 'package',
			dataIndex: 'packageName',
			align: "center",
			sorter: {
				compare: (a, b) =>sorter( a.packageName , b.packageName),
				multiple: 3,
			},
		},

		{
			title: 'used sessions',
			dataIndex: 'UsedSessions',
			align: "center",

			sorter: {
				compare: (a, b) => a.UsedSessions - b.UsedSessions,
				multiple: 1,
			},
		},


		{
			title: 'Remaining sessions',
			dataIndex: 'RemainingSessions',
			align: "center",

			sorter: {
				compare: (a, b) => a.RemainingSessions - b.RemainingSessions,
				multiple: 1,
			},
		},


		{
			title: 'start date',
			dataIndex: 'StartDate',
			align: "center",

			sorter: {
				compare: (a, b) => new Date( a.StartDate) -  new Date( b.StartDate),
				multiple: 1,
			},
		},

		{
			title: 'End Date',
			dataIndex: 'endDate',
			align: "center",
			sorter: {
				compare: (a, b) => new Date(a.endDate) - new Date(b.endDate),
				multiple: 1,
			},
		},
		

		{
			title: 'personal trainer',
			dataIndex: 'PersonalTrainer',
			align: "center",

			sorter: {
				compare: (a, b) => sorter(a.PersonalTrainer, b.PersonalTrainer),
				multiple: 1,
			},
		},
		{
			title: 'Branch',
			dataIndex: 'branch', // Ensure the correct path for 'branch'
			align: 'center',
			sorter: {
			  compare: (a, b) => sorter(a.branch, b.branch), // Apply the sorter for both numeric and string comparisons
			  multiple: 1,
			},
		  },
		  

		{
			title: 'status',
			dataIndex: 'status',
			align: "center",

			sorter: {
				compare: (a, b) => a.status.localeCompare(b.status),
				multiple: 1,
			},
		},
	];


	const classColumns = [
		{
		  title: 'Customer ID',
		  dataIndex: 'CustomerID',
		  align: 'center',
		},
		{
		  title: 'Package',
		  dataIndex: 'packageName',
		  align: 'center',
		  sorter: {
			compare: (a, b) => (a.RemainingSessions + a.UsedSessions) - (b.RemainingSessions + b.UsedSessions),
			multiple: 3,
		  },
		  render: (text, record) => record.RemainingSessions + record.UsedSessions,
		},
		{
		  title: 'Used Sessions',
		  dataIndex: 'UsedSessions',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.UsedSessions - b.UsedSessions,
			multiple: 1,
		  },
		},
		{
		  title: 'Remaining Sessions',
		  dataIndex: 'RemainingSessions',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.RemainingSessions - b.RemainingSessions,
			multiple: 1,
		  },
		},
		{
		  title: 'Start Date',
		  dataIndex: 'StartDate',
		  align: 'center',
		  sorter: {
			compare: (a, b) => new Date(a.StartDate) - new Date(b.StartDate),
			multiple: 1,
		  },
		},
		{
		  title: 'End Date',
		  dataIndex: 'EndDate',
		  align: 'center',
		  sorter: {
			compare: (a, b) => new Date(a.EndDate) - new Date(b.EndDate),
			multiple: 1,
		  },
		},
		{
		  title: 'Personal Trainer',
		  dataIndex: 'Trainer',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.PersonalTrainer - b.PersonalTrainer,
			multiple: 1,
		  },
		},
		{
		  title: 'Status',
		  dataIndex: 'status',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.status.localeCompare(b.status), // Assuming `status` is a string
			multiple: 1,
		  },
		},
	  ];
	  



	  const academyColumns = [
		{
		  title: 'Customer ID',
		  dataIndex: 'CustomerID',
		  align: 'center',
		},
		{
		  title: 'Package',
		  dataIndex: 'packageName',
		  align: 'center',
		  sorter: {
			compare: (a, b) => (a.RemainingSessions + a.UsedSessions) - (b.RemainingSessions + b.UsedSessions),
			multiple: 3,
		  },
		  render: (text, record) => record.RemainingSessions + record.UsedSessions,
		},
		{
			title: 'Academy',
			dataIndex: 'AcademyName',
			align: 'center',
			sorter: {
			  compare: (a, b) => a.AcademyName.localeCompare(b.AcademyName),
			  multiple: 3,
			},
		  },
		  
		{
		  title: 'Used Sessions',
		  dataIndex: 'UsedSessions',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.UsedSessions - b.UsedSessions,
			multiple: 1,
		  },
		},
		{
		  title: 'Remaining Sessions',
		  dataIndex: 'RemainingSessions',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.RemainingSessions - b.RemainingSessions,
			multiple: 1,
		  },
		},
		{
		  title: 'Start Date',
		  dataIndex: 'StartDate',
		  align: 'center',
		  sorter: {
			compare: (a, b) => new Date(a.StartDate) - new Date(b.StartDate),
			multiple: 1,
		  },
		},
		{
		  title: 'End Date',
		  dataIndex: 'EndDate',
		  align: 'center',
		  sorter: {
			compare: (a, b) => new Date(a.EndDate) - new Date(b.EndDate),
			multiple: 1,
		  },
		},
		{
		  title: 'Personal Trainer',
		  dataIndex: 'Trainer',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.PersonalTrainer - b.PersonalTrainer,
			multiple: 1,
		  },
		},
		{
		  title: 'Status',
		  dataIndex: 'status',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.status.localeCompare(b.status), // Assuming `status` is a string
			multiple: 1,
		  },
		},
	  ];


	  const dietColumns = [
		{
		  title: 'Customer ID',
		  dataIndex: 'CustomerID',
		  align: 'center',
		},
		{
		  title: 'Package',
		  dataIndex: 'packname',
		  align: 'center',
		  sorter: {
			compare: (a, b) => new Date(a.packname) - new Date(b.packname),
			multiple: 1,
		  },
		},
		
	
		{
		  title: 'Date',
		  dataIndex: 'Date',
		  align: 'center',
		  sorter: {
			compare: (a, b) => new Date(a.StartDate) - new Date(b.StartDate),
			multiple: 1,
		  },
	
		
		},
		{
		  title: 'Trainer',
		  dataIndex: 'captinName',
		  align: 'center',
		  sorter: {
			compare: (a, b) => a.PersonalTrainer - b.PersonalTrainer,
			multiple: 1,
		  },
		},

		{
			title: 'Branch',
			dataIndex: 'branch',
			align: 'center',
			sorter: {
			  compare: (a, b) => a.PersonalTrainer - b.PersonalTrainer,
			  multiple: 1,
			},
		  },
		
	  ];
	  

	  const clinicColumns = [
		{
		  title: 'Customer ID',
		  dataIndex: 'customerID',
		  align: 'center',
		},
		
	
		{
		  title: 'Date',
		  dataIndex: 'paydate',
		  align: 'center',
		  sorter: {
			compare: (a, b) => new Date(a.StartDate) - new Date(b.StartDate),
			multiple: 1,
		  },
	
		
		},
		
		{
			title: 'Branch',
			dataIndex: 'branch',
			align: 'center',
			sorter: {
			  compare: (a, b) => a.PersonalTrainer - b.PersonalTrainer,
			  multiple: 1,
			},
		  },
		
	  ];

	  
	useEffect(function () {
		async function fetchData() {
			try {
				const res = await fetch(`${API}/customerHistory/${customerID}`)
				const data = await res.json()

				setGym(data.gym)
				setGymPaid(data.gymFinance[0].sumPaid)
				setGymRemaining(data.gymFinance[0].sumRemains)
				setGymReturn(data.gymReturn[0].returnValue)

				setClassPaid(data.classFinance[0].sumPaid)
				setClassRemaining(data.classFinance[0].sumRemains)
				setClassReturn(data.classReturn[0].returnValue)


				setAcademyPaid(data.acadenyFinance[0].sumPaid)
				setAcademyRemaining(data.acadenyFinance[0].sumRemains)
				setAcademyReturn(data.academyReturn[0].returnValue)
				setClinic(data.clinic)
				setClinicPaid(data.clinicFinance[0].sumPaid)
				setClinicRemaining(data.clinicFinance[0].sumRemains)
				setClinicReturn(data.clinicReturn[0].returnValue)

				setClass(data.class)
				setAcademy(data.academy)
				setDiet(data.diet)
				setDietPaid(data.dietFinance[0].sumPaid)


				console.log(diet)


			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchData()
	}, [])


	const onChange = (pagination, filters, sorter, extra) => {
		console.log('params', pagination, filters, sorter, extra);
	};



	const stats = [
		{"title":"GYM", "length":gym?.length, "paid": gymPaid, "Remains":gymRemaining, "return":gymReturn},
		{"title":"Class", "length":classData?.length, "paid":classPaid,"Remains":classRemaining, "return":classReturn},
		{"title":"Academy", "length":academy?.length,  "paid":academyPaid,"Remains":academyRemaining, "return":academyReturn},
		{"title":"Diet", "length":diet?.length, "paid":dietPaid},
		{"title":"Clinic", "length":clinic?.length, "paid":clinicPaid,"Remains":clinicRemaining, "return":clinicReturn}

	]


	function StatsComponent({category}) {
		return (
			<Paper
						elevation={0}
						sx={{
							margin:"auto",
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "200px",
							height: "70px",
							display: "flex",
							justifyContent: "center",
							marginBottom:"10px"

						}}
					>

						<Box sx={{ display: "block", width: "100%" }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px" }}>
									{category.title}
								</Typography>
							</Box>

							<Box display="flex" flexDirection="row" justifyContent="space-evenly">
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{category.length}
							</Typography>

							<Typography sx={{ fontWeight: "bold",color:"green", fontFamily: "poppins" }}>
								{category.paid}
							</Typography>

							<Typography sx={{ fontWeight: "bold",color:"red", fontFamily: "poppins" }}>
								{category.Remains}
							</Typography>

							<Typography sx={{ fontWeight: "bold",color:"grey", fontFamily: "poppins" }}>
								{category.return}
							</Typography>
							</Box>
						</Box>
					</Paper>
		)
	}


	{if (loading) return <Box
		sx={{
		  display: 'flex',
		  justifyContent: 'center',
		  alignItems: 'center',
		  height: '100vh',
		}}
	  >
		<CircularProgress />
	  </Box>}
	
	return (
		<Container>
			<Navbar/>
			<Container sx={{marginTop:"20px"}}>
			
			<Container sx={{ padding: "20px" }}>
			<Container sx={{ padding: "20px" }}>
  <Grid container spacing={2} justifyContent="space-around">
    {stats.map((category, index) => (
      <Grid 
        item 
        key={index} 
        xs={12}  // Full width on extra-small screens
        sm={6}   // Half width on small screens
        md={2.4} // Approx. 1/5 width on medium screens (5 items per row)
        lg={2.4} // Approx. 1/5 width on large screens (5 items per row)
      >
        <StatsComponent category={category} />
      </Grid>
    ))}
  </Grid>
</Container>

</Container>

			<Table 
			  pagination={{ pageSize: 5 }} 

			columns={GYMcolumns} dataSource={gym} onChange={onChange}
			bordered
						scroll={{ x: 'max-content' }}
			/>




<Typography sx={{fontFamily:"poppins", fontSize:"20px", fontWeight:"bold", width:"fit-content", margin:"auto", color:"black"}}>Class</Typography>
			
			<Table 
			bordered
			scroll={{ x: 'max-content' }}
			  pagination={{ pageSize: 5 }} 

			columns={classColumns} dataSource={classData} onChange={onChange} />



<Typography sx={{fontFamily:"poppins", fontSize:"20px", fontWeight:"bold", width:"fit-content", margin:"auto", color:"black"}}>Academy</Typography>
			
			<Table 
			bordered
			scroll={{ x: 'max-content' }}
			  pagination={{ pageSize: 5 }} 

			columns={academyColumns} dataSource={academy} onChange={onChange} />

<Typography sx={{fontFamily:"poppins", fontSize:"20px", fontWeight:"bold", width:"fit-content", margin:"auto", color:"black"}}>Diet</Typography>
			
			<Table 
			bordered
			scroll={{ x: 'max-content' }}
			  pagination={{ pageSize: 5 }} 

			columns={dietColumns} dataSource={diet} onChange={onChange} />


<Typography sx={{fontFamily:"poppins", fontSize:"20px", fontWeight:"bold", width:"fit-content", margin:"auto", color:"black"}}>Clinic</Typography>
			
			<Table 
			bordered
						scroll={{ x: 'max-content' }}
			  pagination={{ pageSize: 5 }} 

			columns={clinicColumns} dataSource={clinic} onChange={onChange} />
			</Container>


			
		</Container>
	)
}


export default CustomerHistory