import { Container, Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import moment from 'moment-timezone';
import qs from 'qs';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker, Space } from 'antd';
import { PieChart } from '@mui/x-charts/PieChart';

import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

function AllAttendance() {
	const API = process.env.REACT_APP_API
	// gym 

	const [gymData, setGymData] = useState([])
	const [gymLoading, setGymLoading] = useState(true)


	// class 
	const [classData, setClassData] = useState([])
	const [classLoading, setClassLoading] = useState(true)


	// Academy 
	const [academyData, setAcademyData] = useState([])
	const [academyLoading, setAcademyLoading] = useState(true)


  // Clinic
  const [clinicData, setClinicDate] = useState([])
	const [clinicLoading, setClinicLoading] = useState(true)

	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));

	const energy = []
	const city = []
	const male = []
	const female = []




	const allAttendates = [...gymData, ...classData, ...academyData]







function pieData() {
		allAttendates.map(m => {
			if (m.Gender == "Male") {
				male.push(m)
			} else if (m.Gender == "Female") {
				female.push(m)
			}
		})
	
		allAttendates.map(m => {
			if (m.Branch == "Energy" || m.Branch == "energy") {
				energy.push(m)
			} else if (m.Branch == "City Club") {
				city.push(m)
			}
		})
	
}

pieData()


	const [tableParams, setTableParams] = useState({

		date: {
			startDate: startDate,
			endDate: endDate

		}


	});



	useEffect(function () {
		async function fetchGymData() {
			try {
				const res = await fetch(`${API}/getAllGymAttendance?${qs.stringify((tableParams))}`)
				const data = await res.json()
				setGymData(data.data)

			} catch (e) {
				console.log(e)
			} finally {
				setGymLoading(false)
			}
		} fetchGymData()
	}, [tableParams.date?.startDate,
	tableParams.date?.endDate,])

  useEffect(function () {
		async function fetchClinicData() {
			try {
				const res = await fetch(`${API}/getAllClinicAttendance?${qs.stringify((tableParams))}`)
				const data = await res.json()
			setClinicDate(data.data)

			} catch (e) {
				console.log(e)
			} finally {
				setClinicLoading(false)
			}
		} fetchClinicData()
	}, [tableParams.date?.startDate,
	tableParams.date?.endDate,])


	useEffect(function () {
		async function fetchClassData() {
			try {
				const res = await fetch(`${API}/getAllClassAttendance?${qs.stringify((tableParams))}`)
				const data = await res.json()

				console.log(data)
				setClassData(data.data)

			} catch (e) {
				console.log(e)
			} finally {
				setClassLoading(false)
			}
		} fetchClassData()
	}, [tableParams.date?.startDate,
	tableParams.date?.endDate,])




	useEffect(function () {
		async function fetchAcademyData() {
			try {
				const res = await fetch(`${API}/getAllAcademyAttendance?${qs.stringify((tableParams))}`)
				const data = await res.json()

				console.log(data)
				setAcademyData(data.data)

			} catch (e) {
				console.log(e)
			} finally {
				setAcademyLoading(false)
			}
		} fetchAcademyData()
	}, [tableParams.date?.startDate,
	tableParams.date?.endDate,])



	const onStartChange = (date, dateString) => {
		setDate(dateString)

		setTableParams({
			...tableParams,

			date: {
				...tableParams.date,
				startDate: dateString

			},
		});
	};

	const onEndChange = (date, dateString) => {

		// setEnDate(dateString);
		setEnDate(dateString)

		setTableParams({
			...tableParams,

			date: {
				...tableParams.date,
				endDate: dateString,

			},




		});
	};


	{
		if (gymLoading || classLoading) return <Box sx={{ display: 'flex' }}>
			<CircularProgress />
		</Box>
	}

	return (
<Container
  sx={{
    border: "1px solid #e4e4e4",
    padding: "20px",
    marginTop: "20px",
    backgroundColor: "#eaf2ff",
    borderRadius: "20px",
    minHeight: "500px", // Ensuring minimum height on larger screens
    width: "100%", // Make the container responsive by using full width
    maxWidth: "100%", // Prevent the container from exceeding the screen width
    "@media (max-width: 600px)": {
      minHeight: "auto", // On smaller screens, allow the container to shrink
      padding: "16px", // Less padding on small screens
    },
  }}
>			<div style={{ minHeight: "500px", backgroundColor: "#ffffff", boxShadow: "0px 7px 30px 5px rgba(0,0,0,0.1)", borderRadius: "7px", padding: "14px", marginBottom: "10px", border: "2px solid #fefefe", display: "block", flexDirection: "column" }}>
				<div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
					<DatePicker style={{ backgroundColor: "#eaedf4", border: "none", marginRight: "10px" }} format="YYYY-MM-DD" placeholder='From' onChange={onStartChange} />
					<DatePicker style={{ backgroundColor: "#eaedf4", border: "none" }} format="YYYY-MM-DD" placeholder='To' onChange={onEndChange} />
				</div>


				<Container
  sx={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",  // Allow wrapping of items on smaller screens
    gap: "16px", // Add some space between items
    "@media (max-width: 600px)": {
      flexDirection: "column", // Stack items vertically on smaller screens
      alignItems: "center", // Center align items
      padding: "16px", // Add padding around the container
    },
    "@media (min-width: 601px)": {
      flexDirection: "row", // Default to row layout on larger screens
      justifyContent: "space-between",
    },
  }}
>
  {/* Gym */}
  <Container
    sx={{
      display: "block",
      backgroundColor: "#e4ecfc",
      height: "80px",
      padding: "7px",
      width: "200px",
      borderRadius: "10px",
      textAlign: "center",
    }}
  >
    <Typography
      fontWeight="bold"
      fontSize="15px"
      fontFamily="poppins"
      color="black"
      sx={{ marginBottom: "8px" }}
    >
      GYM
    </Typography>
    <Typography
      fontWeight="bold"
      fontFamily="poppins"
      color="black"
      fontSize="20px"
    >
      {gymData.length}
    </Typography>
  </Container>

  {/* Class */}
  <Container
    sx={{
      display: "block",
      backgroundColor: "#e4ecfc",
      height: "80px",
      padding: "7px",
      width: "200px",
      borderRadius: "10px",
      textAlign: "center",
    }}
  >
    <Typography
      fontWeight="bold"
      fontSize="15px"
      fontFamily="poppins"
      color="black"
      sx={{ marginBottom: "8px" }}
    >
      Class
    </Typography>
    <Typography
      fontWeight="bold"
      fontFamily="poppins"
      color="black"
      fontSize="20px"
    >
      {classData.length}
    </Typography>
  </Container>

  {/* Academy */}
  <Container
    sx={{
      display: "block",
      backgroundColor: "#e4ecfc",
      height: "80px",
      padding: "7px",
      width: "200px",
      borderRadius: "10px",
      textAlign: "center",
    }}
  >
    <Typography
      fontWeight="bold"
      fontSize="15px"
      fontFamily="poppins"
      color="black"
      sx={{ marginBottom: "8px" }}
    >
      Academy
    </Typography>
    <Typography
      fontWeight="bold"
      fontFamily="poppins"
      color="black"
      fontSize="20px"
    >
      {academyData.length}
    </Typography>
  </Container>

    {/* Total */}
    <Container
    sx={{
      display: "block",
      backgroundColor: "#e4ecfc",
      height: "80px",
      padding: "7px",
      width: "200px",
      borderRadius: "10px",
      textAlign: "center",
    }}
  >
    <Typography
      fontWeight="bold"
      fontSize="15px"
      fontFamily="poppins"
      color="black"
      sx={{ marginBottom: "8px" }}
    >
      Clinic
    </Typography>
    <Typography
      fontWeight="bold"
      fontFamily="poppins"
      color="black"
      fontSize="20px"
    >
      {clinicData.length}
    </Typography>
  </Container>

  {/* Total */}
  <Container
    sx={{
      display: "block",
      backgroundColor: "#e4ecfc",
      height: "80px",
      padding: "7px",
      width: "200px",
      borderRadius: "10px",
      textAlign: "center",
    }}
  >
    <Typography
      fontWeight="bold"
      fontSize="15px"
      fontFamily="poppins"
      color="black"
      sx={{ marginBottom: "8px" }}
    >
      Total
    </Typography>
    <Typography
      fontWeight="bold"
      fontFamily="poppins"
      color="black"
      fontSize="20px"
    >
      {Number(academyData.length) + Number(gymData.length) + Number(classData.length)}
    </Typography>
  </Container>
</Container>

				<div
  style={{
    width: "50%",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "10px",
    margin: "20px auto",
    display: "flex", // Use flexbox for centering
    flexDirection: "column", // Stack the content vertically
    alignItems: "center", // Horizontally center the content
    justifyContent: "center", // Vertically center the content
    textAlign: "center",
    boxShadow: "0px 7px 30px 5px rgba(0,0,0,0.1)"
  }}
>
  <PieChart
    series={[
      {
        data: [
          { id: 0, value: energy.length, label: 'Energy', color: "#8ecf4d" },
          { id: 1, value: city.length, label: 'City Club', color: "#5dba2c" },
        ],
        highlightScope: { faded: 'global', highlighted: 'item' },
        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
      },
    ]}
    height={100}
    width={250}
  />
</div>


<div
  style={{
    width: "50%",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "10px",
    margin: "20px auto",
    display: "flex", // Use flexbox for centering
    flexDirection: "column", // Stack the content vertically
    alignItems: "center", // Horizontally center the content
    justifyContent: "center", // Vertically center the content
    textAlign: "center",
    boxShadow: "0px 7px 30px 5px rgba(0,0,0,0.1)"
  }}
>			
				<PieChart
						series={[
							{

								data:[
									{ id: 0, value: male.length, label: 'Male', color: "#1c3353" },
		{ id: 1, value: female.length, label: 'Female', color: "#467fcf" },
								]
							,
								highlightScope: { faded: 'global', highlighted: 'item' },
								faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
							},
						]}
						height={100}
						width={250}
					/>

			




			</div>

			</div>
		</Container>
	)
}


export default AllAttendance